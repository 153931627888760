import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { CartesianGrid, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis, ZAxis } from "recharts";
import styles from "./BubbleChart.module.scss";

interface BubbleChartProps {
	data: Array<{ [key: string]: any }>;
	xKey: string;
	yKey: string;
	zKey: string;
	xLabel?: string;
	yLabel?: string;
	zLabel?: string;
	xDomain?: [number, number];
	yDomain?: [number, number];
	zRange?: [number, number];
}

type TooltipPayload = {
	name: string;
	value: string;
	payload: { name: string };
};

type CustomTooltipProps = {
	payload?: TooltipPayload[];
};

const formatTooltipValue = (value: string, name: string): string => {
	return `${value.replace("_", " ")}: ${name}`;
};

const CustomTooltipContent: React.FC<CustomTooltipProps> = ({ payload }) => {
	if (!payload || !payload.length) return null;

	return (
		<Box className={styles.customRechartsTooltip}>
			<Typography className={styles.rechartsTooltipLabel}>{payload[0].payload?.name}</Typography>
			<ul className={styles.rechartsTooltipItemList}>
				{payload.map((payloadItem, index) => (
					<li key={index}>
						<Typography className={styles.rechartsTooltipItem}>
							{formatTooltipValue(payloadItem.name, payloadItem.value)}
						</Typography>
					</li>
				))}
			</ul>
		</Box>
	);
};

const COLORS = [
	"#0095FF", // --color-0
	"#07E098", // --color-1
	"#ffcf00", // --color-2
	"#FFA412", // --color-3
	"#FF5733", // --color-4
	"#dc3545", // --color-5
	"#5D5FEF", // --color-6
];

// Function to generate lighter/darker shades of a color
const generateDynamicColor = (baseColor: string, variation: number): string => {
	let color = baseColor.replace("#", "");
	let num = parseInt(color, 16);

	let r = (num >> 16) + variation;
	let g = ((num >> 8) & 0x00ff) + variation;
	let b = (num & 0x0000ff) + variation;

	r = Math.max(0, Math.min(255, r));
	g = Math.max(0, Math.min(255, g));
	b = Math.max(0, Math.min(255, b));

	return `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, "0")}`;
};

const getBubbleColors = (dataLength: number): string[] => {
	const colors: string[] = [];
	for (let i = 0; i < dataLength; i++) {
		if (i < COLORS.length) {
			colors.push(COLORS[i]);
		} else {
			// Generate dynamic colors based on base colors
			const baseColor = COLORS[i % COLORS.length];
			const variation = (i % 2 === 0 ? 20 : -20) * Math.floor(i / COLORS.length);
			colors.push(generateDynamicColor(baseColor, variation));
		}
	}
	return colors;
};

const SharedBubbleChart: React.FC<BubbleChartProps> = ({
	data,
	xKey,
	yKey,
	zKey,
	xLabel = "X-axis",
	yLabel = "Y-axis",
	zLabel = "Z-axis",
	xDomain = [0, 10],
  yDomain = [0, 80],
	zRange = [100, 10000],
}) => {
	const bubbleColors = getBubbleColors(data.length);

	// Add dynamic colors to data
	const coloredData = data.map((entry, index) => ({
		...entry,
		fill: bubbleColors[index],
	}));


	const chartRef = useRef<HTMLDivElement>(null);
  const [zoomedXDomain, setZoomedXDomain] = useState(xDomain);
  const [zoomedYDomain, setZoomedYDomain] = useState(yDomain);

  // Handle Mouse Scroll Zoom
  const handleMouseWheel = (event: WheelEvent) => {
    event.preventDefault();

    const zoomFactor = event.deltaY > 0 ? 1.1 : 0.9; // Zoom out or in
    setZoomedXDomain((prev: any) => adjustDomain(prev, zoomFactor));
    setZoomedYDomain((prev: any) => adjustDomain(prev, zoomFactor));
  };

  // Adjust domain based on zoom factor
  const adjustDomain = (domain: [number, number], factor: number): [number, number] => {
    const [min, max] = domain;
    const range = max - min;
    const newMin = min + (range * (factor - 1)) / 2;
    const newMax = max - (range * (factor - 1)) / 2;
    return [newMin, newMax];
  };

  // Attach mouse scroll event listener
  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      chart.addEventListener("wheel", handleMouseWheel);
    }
    return () => {
      if (chart) {
        chart.removeEventListener("wheel", handleMouseWheel);
      }
    };
  }, []);


	return (
		<Box ref={chartRef}>
			<ResponsiveContainer width="100%" height={400}>
				<ScatterChart margin={{ top: 30, right: 30, bottom: 50, left: 20 }}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis
						type="number"
						dataKey={xKey}
						domain={zoomedXDomain}
						name={xLabel}
						label={{
							value: xLabel,
							position: "insideBottom",
							offset: -20,
							fill: "#3E4C59",
							fontSize: 14,
							fontFamily: "Lato, sans-serif",
							fontWeight: "bold",
						}}
						tick={({ payload, x, y, dy }) => (
							<text
								x={x}
								y={y + 20}
								dy={dy}
								textAnchor="middle"
								fill="#7B91B0"
								fontSize={13}
								fontFamily="Lato, sans-sarif"
							>
								{payload.value}
							</text>
						)}
					/>
					<YAxis
						type="number"
						dataKey={yKey}
						domain={zoomedYDomain}
						name={yLabel}
						label={{
							value: yLabel,
							angle: -90,
							position: "insideLeft",
							fill: "#3E4C59",
							fontSize: 14,
							fontFamily: "Lato, sans-serif",
							fontWeight: "bold",
						}}
						tick={{
							fill: "#7B91B0",
							fontSize: 12,
							fontFamily: "Lato, sans-sarif",
						}}
					/>
					<ZAxis type="number" dataKey={zKey} range={zRange} name={zLabel} />
					<Tooltip content={<CustomTooltipContent />} cursor={{ strokeDasharray: "3 3" }} />

					{/* Scatter with dynamic colors */}
					<Scatter
						name="Bubble chart"
						data={coloredData}
						fill="auto" // Dynamic colors are in 'fill' of data points
						shape="circle"
					/>
				</ScatterChart>
			</ResponsiveContainer>
		</Box>
	);
};

export default SharedBubbleChart;
