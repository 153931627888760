import styled from "@emotion/styled";
import { Button, ButtonProps } from "@mui/material";
import { CSSProperties, FC } from "react";
import styles from "./TableButton.module.scss";

export type ButtonTypes =
	| "primary"
	| "outlined"
	| "tertiaryMedium"
	| "secondaryMedium"
	| "textSmall"
	| "primaryMedium"
	| "textMedium";

interface IProps {
	buttonType?: ButtonTypes;
	style?: CSSProperties;
	className?: any;
}

const StyledButton = styled(Button)<IProps>`
	&.Mui-disabled {
		color: unset;
	}
`;

const TableButton: FC<IProps & ButtonProps> = ({ children, buttonType, style, className, ...rest }) => {
	return (
		<StyledButton
			{...rest}
			style={style}
			// disabledColor={disabledColor}
			className={`${buttonType ? styles[buttonType] : ""} ${styles.textCommonStyle} ${className}`}
		>
			{children}
		</StyledButton>
	);
};

export default TableButton;
