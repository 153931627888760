import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import styles from "./AuthLayout.module.scss";
import { isLoggedIn } from "@utils/middleware";

const AuthLayout: React.FC = () => {

	if(isLoggedIn()) {
		return <Navigate to="/" />;
	}

	return (
		<>
			<div className={styles.container}>
				<Outlet />
			</div>
		</>
	);
};

export default AuthLayout;
