import RouterConstants from "@constants/RouterConstants";
import useErrorToast from "@hooks/useErrorToast";
import useSuccessToast from "../hooks/useSuccessToast";

import { useAppStore } from "@hooks/useStores";
import { TableRow } from "@layouts/TableLayout/TableLayout";
import environmentStore from "@mobx/EnvironmentStore";
import { Widget, WidgetTableRow } from "@models/widgets";
import { ContentCopy, Delete, Edit } from "@mui/icons-material";
import { Box } from "@mui/material";
import { EditWidgetState } from "@pages/Widget/Widget";
import styles from "@pages/Widgets/Widgets.module.scss";
import { toJS } from "mobx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const useWidgetsLogic = () => {
	const { t: ct } = useTranslation("translation");
	const { t } = useTranslation("translation", { keyPrefix: "widgets" });
	const { widgetsStore } = useAppStore();
	const [tableData, setTableData] = useState<TableRow<WidgetTableRow>[]>();
	const [openDeletePopup, setOpenDeletePopup] = useState(false);
	const [_widgetToDelete, setWidgetToDelete] = useState<Widget>();
	const navigate = useNavigate();
	const { showError } = useErrorToast();
	const { showSuccess } = useSuccessToast();
	const headerArray = [
		t("labels.name"),
		t("labels.description"),
		t("labels.environment"),
		t("labels.template"),
		t("labels.type"),
		t("labels.actions"),
	];

	const createRow = (widget: Widget, index: number): TableRow<WidgetTableRow> => {
		return {
			rowStyle: {
				backgroundColor: index % 2 === 0 ? "#D9D9D91A" : "transparent",
			},
			key: widget.id,
			data: {
				name: <Box className={styles.nameStyle}>{widget.name}</Box>,
				description: <Box className={styles.nameStyle}>{widget.description}</Box>,
				environment: (
					<Box className={styles.environmentStyle}>
						<span className={widget.environment === "STAGING" ? styles.stagingEnv : styles.productionEnv}>
							{widget.environment}
						</span>
					</Box>
				),
				template: <Box className={styles.nameStyle}>{widget.template?.toLowerCase()}</Box>,
				type: <Box className={styles.nameStyle}>{widget.type?.toLowerCase()}</Box>,
				actions: (
					<Box className={styles.actionsContainer}>
						{[
							{
								icon: <Edit className={styles.actionBtn} />,
								onClick: () => handleModifyWidget(widget),
							},
							{
								icon: <Delete className={styles.actionBtn} />,
								onClick: () => handleOpenDeletePopup(widget),
							},
							{
								icon: <ContentCopy className={styles.actionBtn} />,
								onClick: () => handlePageCopy(widget),
							},
						].map((action, index) => (
							<Box key={index} onClick={action.onClick}>
								{action.icon}
							</Box>
						))}
					</Box>
				),
			},
		};
	};

	const handlePageCopy = (widget: Widget) => {
		if (widget.id) {
			navigator.clipboard
				.writeText(`<div class="sva-widget-id" data-widget-id="${widget.id}"></div>`)
				.then(() => {
					showSuccess(ct("success.defaultMessage"));
				})
				.catch((error) => {
					showError(ct("error.failedToCopy"));
					console.error("Error copying widget ID to clipboard:", error);
				});
		} else {
			showError(ct("error.widgetIdNotAvailable"));
		}
	};

	const createNewWidget = async () => {
		navigate(RouterConstants.WIDGET.path);
	};

	const deleteBrand = async () => {
		if (_widgetToDelete?.id) {
			closeDeletePopup();

			await widgetsStore.deleteWidget(_widgetToDelete?.id).then(() => {
				getWidgets();
			});
		}
	};

	const handleOpenDeletePopup = (widget: Widget) => {
		setOpenDeletePopup(true);
		setWidgetToDelete(widget);
	};

	const handleModifyWidget = (widget: Widget) => {
		const editWidgetState: EditWidgetState = {
			WidgetToEdit: toJS(widget),
		};
		navigate(`${RouterConstants.WIDGET.path}/${widget.id}`, { state: editWidgetState });
	};

	const closeDeletePopup = () => {
		setOpenDeletePopup(false);
		setWidgetToDelete(undefined);
	};

	const getWidgets = () => {
		widgetsStore
			.getWidgets()
			.then(() => {
				const tableRows = widgetsStore.widgets.map((widget, index) => createRow(widget, index));
				setTableData(tableRows);
			})
			.catch((error) => {
				showError(error);
			});
	};

	useEffect(() => {
		getWidgets();
	}, [environmentStore.environment]);

	return {
		tableData,
		headerArray,
		createRow,
		createNewWidget,
		deleteBrand,
		handleOpenDeletePopup,
		handleModifyWidget,
		closeDeletePopup,
		openDeletePopup,
		widgetsStore,
	};
};
