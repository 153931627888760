import { useCreatorsLogic } from "@hooks/useCreatorsLogic";
import PageLayout from "@layouts/PageLayout";
import TableLayout from "@layouts/TableLayout/TableLayout";
import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import styles from "./Creators.module.scss";
import CustomCreatorsFilters from "./CustomCreatorsFilters";

const Creators = () => {
	const { t } = useTranslation("translation", { keyPrefix: "creators.table" });
	const { headerArray, tableData, paginationOptions, searchCreators, searchLoading, getCreators } = useCreatorsLogic();

	const customFilters = <CustomCreatorsFilters onFilterChange={getCreators} />;

	return (
		<PageLayout title={t("titlePage")}>
			<Box className={styles.container}>
				<TableLayout
					// title={t("title")}
					enableSearch={false}
					headerArray={headerArray}
					tableData={tableData}
					hideSecondaryButton={true}
					className={styles.tableLayoutStyle}
					paginationOptions={paginationOptions}
					handleSearchChange={searchCreators}
					searchLoading={searchLoading}
					customFilters={customFilters}
					loading={searchLoading}
					sortable
				/>
			</Box>
		</PageLayout>
	);
};

export default observer(Creators);
