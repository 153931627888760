import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import styles from "./CustomTabs.module.scss";

interface TabContent {
	label: string;
	content: React.ReactNode;
}

interface SharedTabsProps {
	tabs: TabContent[];
}

const CustomTabs: React.FC<SharedTabsProps> = ({ tabs }) => {
	const [activeTab, setActiveTab] = React.useState(0);

	const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
		setActiveTab(newValue);
	};

	return (
		<Box>
			<Tabs value={activeTab} onChange={handleTabChange} className={styles.tabsContainer}>
				{tabs.map((tab, index) => (
					<Tab key={index} label={tab.label} className={styles.tabItem} />
				))}
			</Tabs>
			{tabs.map((tab, index) => (
				<div
					key={index}
					role="tabpanel"
					hidden={activeTab !== index}
					id={`tabpanel-${index}`}
					aria-labelledby={`tab-${index}`}
				>
					{activeTab === index && <Box className={styles.tabContent}>{tab.content}</Box>}
				</div>
			))}
		</Box>
	);
};

export default CustomTabs;
