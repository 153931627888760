import QueryService from "@utils/QueryService.ts";
import { makeAutoObservable, runInAction } from "mobx";

export interface IProductStore {
	isError: boolean;
	isLoading: boolean;

	searchProducts({ query }: { query?: string }): Promise<void>;

	fetchProductsByIds({ productIds }: { productIds?: any[] }): Promise<any>;
}

export default class ProductStore implements IProductStore {
	productService = new QueryService("/shopify/products");
	isError: boolean = false;
	isLoading: boolean = false;

	constructor() {
		makeAutoObservable(this);
	}

	async fetchProductsByIds({ productIds }: { productIds?: [] | [] }): Promise<any> {
		try {
			this.isError = false;
			this.isLoading = true;
			return await this.productService.get(`/${productIds?.join(",")}`);
		} catch (error) {
			console.error("Error fetching video:", error);
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			throw error;
		}
	}

	async searchProducts({ query }: { query?: string }): Promise<void> {
		try {
			this.isError = false;
			this.isLoading = true;
			return await this.productService.get(`/search?query=${query}&count=5`);
		} catch (error) {
			console.error("Error fetching video:", error);
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			throw error;
		}
	}
}
