import CloseIcon from "@mui/icons-material/CloseTwoTone";
import { Alert, Box, ButtonProps, CircularProgress, Dialog, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import TableButton from "../TableButton/TableButton";
import styles from "./ConfirmationPopup.module.scss";

interface IProps {
	open: boolean;
	title?: string;
	description?: string;
	subTitle?: string;
	confirmButtonText: string;
	hideCancel?: boolean;
	onClick: () => void;
	onClose: () => void;
	onSecondaryClick?: () => void;
	children?: React.ReactNode;
	disableConfirm?: boolean;
	firstButtonText?: string;
	errorMessage?: string | null;
	loading?: boolean;
	paperClassName?: string;
	maxWidth?: string;
}

export default function ConfirmationPopup({
	open,
	title,
	description,
	subTitle,
	confirmButtonText,
	onClick,
	onClose,
	hideCancel,
	children,
	disableConfirm,
	firstButtonText,
	onSecondaryClick,
	errorMessage,
	loading = false,
	paperClassName = "",
	maxWidth = "552px",
}: IProps & ButtonProps) {
	const { t } = useTranslation();

	return (
		<Dialog
			classes={{
				paper: `${styles.paper} ${paperClassName}`,
			}}
			onClose={onClose}
			open={open}
			maxWidth={false}
			PaperProps={{
				style: {
					maxWidth: maxWidth, // Set your desired width in pixels
				},
			}}
		>
			<CloseIcon onClick={onClose} className={styles.closeIcon} />
			<Typography className={styles.title}>{title}</Typography>
			<Typography className={styles.description}>{description}</Typography>
			{subTitle && <Typography className={styles.subTitle}>{subTitle}</Typography>}
			{children}
			<Box className={styles.buttonsContainer}>
				<Box>
					{errorMessage && (
						<Alert severity="error" variant="standard" className={styles.errorMessage}>
							{errorMessage}
						</Alert>
					)}
				</Box>
				<Box className={styles.buttons}>
					{!hideCancel && (
						<TableButton
							onClick={onSecondaryClick ?? onClose}
							className={styles.cancelBtn}
							buttonType="tertiaryMedium"
						>
							{firstButtonText ? firstButtonText : t("cancel")}
						</TableButton>
					)}

					<TableButton
						className={styles.confirmBtn}
						disabled={disableConfirm}
						onClick={onClick}
						buttonType="primaryMedium"
					>
						{confirmButtonText}
						{loading && (
							<Box className={styles.circleLoader}>{<CircularProgress color="inherit" size={25} />}</Box>
						)}
					</TableButton>
				</Box>
			</Box>
		</Dialog>
	);
}
