import { formatDate } from "@utils/index";
import QueryService from "@utils/QueryService";

interface ChartData {
	date: string;
	value: number;
	count?: any;
}

class AnalyticsService {
	private queryService: QueryService;

	constructor() {
		this.queryService = new QueryService("/report");
	}

	private async fetchChartData(
		endpoint: string,
		startDate: Date,
		endDate: Date,
		extraParams?: object,
	): Promise<ChartData[]> {
		try {
			const formattedStartDate = formatDate(startDate, "start");
			const formattedEndDate = formatDate(endDate, "end");
			const response = await this.queryService.get(endpoint, {
				params: { start_date: formattedStartDate, end_date: formattedEndDate, ...extraParams },
			});
			return response || [];
		} catch (error) {
			console.error(`Error fetching chart data from ${endpoint}:`, error);
			throw new Error("Failed to fetch chart data.");
		}
	}

	async loadEngagementChart(startDate: Date, endDate: Date): Promise<ChartData[]> {
		return await this.fetchChartData("/engagements", startDate, endDate);
	}

	async loadSentimentDistributionChart(startDate: Date, endDate: Date): Promise<ChartData[]> {
		return await this.fetchChartData("/sentiments", startDate, endDate);
	}

	async loadEmotionBreakdownChart(startDate: Date, endDate: Date): Promise<ChartData[]> {
		return await this.fetchChartData("/emotions", startDate, endDate);
	}

	async loadTopProductsChart(startDate: Date, endDate: Date): Promise<ChartData[]> {
		return await this.fetchChartData("/top-products", startDate, endDate);
	}

	async loadVideosCount(startDate: Date, endDate: Date): Promise<ChartData[]> {
		return await this.fetchChartData("/videos-count", startDate, endDate);
	}

	async loadProductPerformanceChart(startDate: Date, endDate: Date, product_id: number): Promise<ChartData[]> {
		return await this.fetchChartData("/product-performance", startDate, endDate, { product_id: product_id });
	}

  async loadProductSentimentChart(startDate: Date, endDate: Date, product_id: number): Promise<ChartData[]> {
		return await this.fetchChartData("/product-sentiment", startDate, endDate, { product_id: product_id });
	}

  async loadCreatorsEngagementsChart(startDate: Date, endDate: Date): Promise<ChartData[]> {
		return await this.fetchChartData("/tiktoker-engagements", startDate, endDate);
	}

  async loadCreatorsVideosCountChart(startDate: Date, endDate: Date, limit: number): Promise<ChartData[]> {
		return await this.fetchChartData("/tiktoker-videos-count", startDate, endDate, { limit: limit});
	}
}

export default AnalyticsService;
