import { Box, Typography } from "@mui/material";
import i18n from "i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function showErrorToast(errorMessage?: string, toastId?: string) {
	const taostId = toast.error(
		<Box>
			<Typography className="toastHeader">{i18n.t("errors.ohSomethingWentWrong")}</Typography>
			<Typography className="toastMessage">{errorMessage ?? i18n.t("errors.pleaseTryAgainLater")}</Typography>
		</Box>,
		{
			toastId,
			type: toast.TYPE.ERROR,
			autoClose: 2000,
			position: "bottom-left",
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: false,
		},
	);
	return taostId.toString();
}

export function showSuccessToast(successMessage?: string, toastId?: string) {
	const taostId = toast.success(
		<Box>
			<Typography className="toastMessage">{successMessage ?? i18n.t("success.defaultMessage")}</Typography>
		</Box>,
		{
			toastId,
			type: toast.TYPE.SUCCESS,
			autoClose: 2000,
			position: "bottom-left",
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: false,
		},
	);
	return taostId.toString();
}
