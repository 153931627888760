import { Box, Typography } from "@mui/material";
import { Area, AreaChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import styles from "./AreaChart.module.scss";

type TooltipPayload = {
	name: string;
	value: string;
	payload: { date: string, label?: string };
};

type CustomTooltipProps = {
	payload?: TooltipPayload[];
};

const formatLegendValue = (data: any, value: string, name: any): JSX.Element => {
	const totalVal = data.reduce((accumulator: any, currentValue: string) => {
		if (Object.keys(currentValue).includes(name.dataKey)) {
			const dataValue = currentValue[name.dataKey as keyof typeof currentValue];
			return accumulator + (typeof dataValue === "number" ? dataValue : 0);
		}
		return accumulator;
	}, 0);

	return (
		<Box className={styles.customLegendItemTextGroup}>
			<Typography className={styles.customLegendItemText}>{value.replace("_", " ")}</Typography>
			<Typography className={styles.customLegendItemValue}>{totalVal}</Typography>
		</Box>
	);
};

const formatTooltipValue = (value: string, name: string): string => {
	return `${value.replace("_", " ")}: ${name}`;
};

const CustomTooltipContent: React.FC<CustomTooltipProps> = ({ payload }) => {
	if (!payload || !payload.length) return null;

	return (
		<Box className={styles.customRechartsTooltip}>
			<Typography className={styles.rechartsTooltipLabel}>{payload[0].payload?.label}</Typography>
			<ul className={styles.rechartsTooltipItemList}>
				{payload.map((payloadItem, index) => (
					<li key={index}>
						<Typography className={styles.rechartsTooltipItem}>{formatTooltipValue(payloadItem.name, payloadItem.value)}</Typography>
					</li>
				))}
			</ul>
		</Box>
	);
};

interface AreaChartProps {
	data: any;
}

const CustomAreaChart: React.FC<AreaChartProps> = ({ data }) => {
	const keys = Object.keys(data[0]).filter((key) => key !== "label");
	return (
		<ResponsiveContainer width="100%" height="100%">
			<AreaChart width={730} height={250} data={data} margin={{ top: 10, right: 10, left: -35, bottom: 0 }}>
				<defs>
					{keys.map((key, index) => (
						<linearGradient key={key} id={`color${key}`} x1="0" y1="0" x2="0" y2="1">
							<stop offset="5%" stopColor={`var(--color-${index % 10})`} stopOpacity={0.2} />
							<stop offset="95%" stopColor={`var(--color-${index % 10})`} stopOpacity={0} />
						</linearGradient>
					))}
				</defs>
				<Tooltip content={<CustomTooltipContent />} />
				{keys.map((key, index) => (
					<Area
						key={key}
						type="monotone"
						dataKey={key}
						stroke={`var(--color-${index % 10})`}
						fill={`url(#color${key})`}
						strokeWidth={2}
						dot={{ stroke: `var(--color-${index % 10})`, fill: `var(--color-${index % 10})` }}
					/>
				))}
				<Legend formatter={(value, name) => formatLegendValue(data, value, name)} />
				<XAxis
					dataKey="label"
					tickSize={0}
					axisLine={{ stroke: "#E0E0E0", strokeWidth: 1 }}
					padding={{ left: 20 }}
					tick={({ payload, x, y, dy }) => (
						<text
							x={x}
							y={y + 20}
							dy={dy}
							textAnchor="middle"
							fill="#7B91B0"
							fontSize={13}
							fontFamily="Lato, sans-sarif"
						>
							{payload.value}
						</text>
					)}
				/>
				<YAxis
					tickSize={0}
					axisLine={{ stroke: "#E0E0E0", strokeWidth: 1 }}
					ticks={[100, 200, 300]}
					tick={{
						fill: "#7B91B0",
						fontSize: 12,
						fontFamily: "Lato, sans-sarif",
					}}
				/>
			</AreaChart>
		</ResponsiveContainer>
	);
};

export default CustomAreaChart;
