import { AxiosRequestConfig } from "axios";
import {Axios} from "@config/axios.tsx";

class QueryService {
	private baseURL: string;

	constructor(baseURL: string) {
		this.baseURL = baseURL;
	}

	async get(url: string, config?: AxiosRequestConfig<any>): Promise<any> {
		const response = await Axios().get(`${this.baseURL}${url}`, config);
		return response.data;
	}

	async post(url: string, data: any, headers: Record<string, string> = {}, options = {}): Promise<any> {
		const response = await ( Axios(headers)).post(`${this.baseURL}${url}`, data, options);
		return response.data;
	}

	async put(url: string, data: any): Promise<any> {
		const response = await ( Axios()).put(`${this.baseURL}${url}`, data);
		return response.data;
	}

	async delete(url: string): Promise<any> {
		const response = await ( Axios()).delete(`${this.baseURL}${url}`);
		return response.data;
	}
}

export default QueryService;
