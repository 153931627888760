import ResentInsights from "@components/common/ResentInsights/ResentInsights";
import SentimentDistributionChart from "@components/dashboard/SentimentDistributionChart/SentimentDistributionChart";
import TopProducts from "@components/dashboard/TopProducts/TopProducts";
import VideosChart from "@components/dashboard/VideosChart/VideosChart";
import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import styles from "./Home.module.scss";

const Home = () => {
	return (
		<>
			<Box className={styles.chartsRow}>
				<Box className={clsx(styles.BoxContainer, styles.resentInsightsContainer)}>
					<ResentInsights />
				</Box>
				{/* Sentiment Distribution */}
				<Box className={clsx(styles.BoxContainer, styles.resentSentimentDistribution)}>
					<SentimentDistributionChart previewOnly={true} height={200} legendPlacement="right" />
				</Box>
			</Box>

			<Box className={styles.chartsRow}>
				{/* Engagement Overview */}

				<Box className={styles.BoxContainer}>
					<VideosChart previewOnly={true} />
				</Box>

				{/* Sentiment Distribution */}
				<Box className={styles.BoxContainer}>
					<TopProducts previewOnly={true} />
				</Box>
			</Box>
		</>
	);
};

export default observer(Home);
