import DebouncedInput from "@components/common/DebouncedInput/DebouncedInput";
import { useAppStore } from "@hooks/useStores";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./Creators.module.scss";

const CustomCreatorsFilters = ({ onFilterChange }: { onFilterChange: () => void }) => {
	const { t } = useTranslation("translation", { keyPrefix: "creators.table" });
	const { creatorsStore } = useAppStore();

	const handleFilterChange = (key: string, value: string) => {
		creatorsStore.setFilter(key, value);
		onFilterChange();
	};

	return (
		<Box className={styles.customFilters}>
			{/* Tiktoker Name Input */}
			<DebouncedInput
				label={t("filters.tiktokerName")}
				variant="outlined"
				value={creatorsStore.filters.tiktokerName}
				onChange={(e) => handleFilterChange("tiktokerName", e.target.value)}
				className={styles.filterInput}
				sx={{
					width: "200px",
					"& .MuiInputLabel-root": {
						transform: "translate(14px, 10px) scale(1)",
						fontSize: 14
					},
					"& .MuiInputLabel-shrink": {
						transform: "translate(14px, -6px) scale(0.75)",
					},
					"& .MuiOutlinedInput-input": {
						padding: "8px 14px",
						height:"24px",
					},
				}}
			/>
			<DebouncedInput
				label={t("headers.followers")}
				variant="outlined"
				className={styles.filterInput}
				sx={{
					width: "200px",
					"& .MuiInputLabel-root": {
						transform: "translate(14px, 10px) scale(1)",
						fontSize: 14
					},
					"& .MuiInputLabel-shrink": {
						transform: "translate(14px, -6px) scale(0.75)",
					},
					"& .MuiOutlinedInput-input": {
						padding: "8px 14px",
						height:"24px",
					},
				}}
				disabled
			/>
			<DebouncedInput
				label={t("headers.hearts")}
				variant="outlined"
				className={styles.filterInput}
				sx={{
					width: "200px",
					"& .MuiInputLabel-root": {
						transform: "translate(14px, 10px) scale(1)",
						fontSize: 14
					},
					"& .MuiInputLabel-shrink": {
						transform: "translate(14px, -6px) scale(0.75)",
					},
					"& .MuiOutlinedInput-input": {
						padding: "8px 14px",
						height:"24px",
					},
				}}
				disabled
			/>
			<DebouncedInput
				label={t("headers.videos")}
				variant="outlined"
				className={styles.filterInput}
				sx={{
					width: "200px",
					"& .MuiInputLabel-root": {
						transform: "translate(14px, 10px) scale(1)",
						fontSize: 14
					},
					"& .MuiInputLabel-shrink": {
						transform: "translate(14px, -6px) scale(0.75)",
					},
					"& .MuiOutlinedInput-input": {
						padding: "8px 14px",
						height:"24px",
					},
				}}
				disabled
			/>
		</Box>
	);
};

export default CustomCreatorsFilters;
