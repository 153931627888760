import { formatDistanceToNow } from "date-fns";

export const getFormattedDate = (createdDate: string | number) =>{
    const date = new Date(createdDate);
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate
}

export const convertTimeToSocialFormat = (timestamp: number): string => {
    if (!timestamp) {
        return "";
    }
    const date = new Date(timestamp * 1000);

    return `${formatDistanceToNow(date, { addSuffix: true })}`;
};