export const modalStyles = {
	modalContainer: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		boxShadow: 24,
		p: 4,
	},
	aiModalContainer: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		backgroundColor: "white",
		boxShadow: 24,
		p: 4,
	},
};
