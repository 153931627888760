import ChartHeader from "@components/common/ChartHeader/ChartHeader";
import CustomStackedBarChart from "@components/common/StackedBarChart/StackedBarChart";
import AnalyticsService from "@services/AnalyticsService";
import React, { useEffect, useState } from "react";
import styles from "./EmotionsChart.module.scss";
import { useDateRange } from "@hooks/useDateRange";

interface EmotionsProps {
	previewOnly?: boolean;
}

const EmotionsChart: React.FC<EmotionsProps> = ({ previewOnly }) => {
	const analyticsService = new AnalyticsService();
  const { dateRange, handleDateChange } = useDateRange();
	const [emotionData, setEmotionData] = useState<any[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		fetchEmotionData(dateRange[0], dateRange[1]);
	}, [dateRange]);

	const fetchEmotionData = async (startDate: Date, endDate: Date): Promise<void> => {
		setLoading(true);
		try {
			const response = await analyticsService.loadEmotionBreakdownChart(startDate, endDate);

			if (response) {
				const transformedData = response.reduce((acc: any, item: any) => {
					const existing = acc.find((entry: any) => entry.date === item.date);
					if (existing) {
						existing[item.label] = item.value;
					} else {
						acc.push({ date: item.date, [item.label]: item.value });
					}
					return acc;
				}, []);

				setEmotionData(transformedData);
			}
		} catch (error) {
			console.error("Error fetching emotion data:", error);
		} finally {
			setLoading(false);
		}
	};

	// Extract unique emotion labels for stack keys
	const stackKeys = Array.from(
		new Set(emotionData.flatMap((item) => Object.keys(item).filter((key) => key !== "date"))),
	);

	return (
		<div className={styles.emotionChartWrap}>
			<ChartHeader
				title="Emotion Breakdown"
				onDateChange={handleDateChange}
				value={dateRange}
				loading={loading}
				previewOnly={previewOnly}
			/>
			<div className={styles.blockContentWrap}>
				<CustomStackedBarChart
					data={emotionData}
					xAxisKey="date"
					stackKeys={stackKeys}
					title="Emotion Breakdown"
				/>
			</div>
		</div>
	);
};

export default EmotionsChart;
