import { DeleteRounded } from "@mui/icons-material";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { IconButton } from "rsuite";
import styles from "./ProductCard.module.scss";

interface VideoCardProps {
	thumbnail: string | undefined;
	description?: string;
	id: string;
	onRemove: (product: any) => void;
}

const ProductCard: React.FC<VideoCardProps> = ({ thumbnail, description, id, onRemove }) => {
	return (
		<Box className={styles.videoCartContainer}>
			<Box className={styles.imageContainer}>
				<CardMedia
					component="img"
					className={styles.thumbnail}
					image={thumbnail}
					alt="Live from space album cover"
				/>
			</Box>

			<Box className={styles.cardContentWrapper}>
				<CardContent className={styles.cardContent}>
					<Typography variant="subtitle1" component="p" className={styles.videoDescription}>
						{description}
					</Typography>
				</CardContent>
				<Box className={styles.actions}>
					<IconButton className={styles.actionBtn} onClick={() => onRemove(id)}>
						<DeleteRounded />
					</IconButton>
				</Box>
			</Box>
		</Box>
	);
};

export default ProductCard;
