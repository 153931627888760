import CustomAreaChart from "@components/common/AreaChart/AreaChart";
import ChartHeader from "@components/common/ChartHeader/ChartHeader";
import { useDateRange } from "@hooks/useDateRange";
import { Box, Typography } from "@mui/material";
import AnalyticsService from "@services/AnalyticsService";
import React, { useEffect, useState } from "react";
import styles from "./EngagementChart.module.scss";

interface EngagementData {
	date: string;
	value: number;
}

interface EngagementProps {
	previewOnly?: boolean;
}

const EngagementChart: React.FC<EngagementProps> = ({ previewOnly }) => {
	const analyticsService = new AnalyticsService();
	const { dateRange, handleDateChange } = useDateRange();
	const [engagements, setEngagements] = useState<EngagementData[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		loadEngagementsData(dateRange[0], dateRange[1]);
	}, [dateRange]);

	const loadEngagementsData = async (startDate: Date, endDate: Date): Promise<void> => {
		setLoading(true);
		try {
			const response = await analyticsService.loadEngagementChart(startDate, endDate);
			if (response) {
				setEngagements(response);
			}
		} catch (error) {
			console.error("Error fetching engagements data:", error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Box>
			<ChartHeader
				title="Engagement Overview"
				onDateChange={handleDateChange}
				value={dateRange}
				loading={loading}
				previewOnly={previewOnly}
			/>
			<Box className={styles.blockContentWrap}>
				{loading ? (
					<Typography>Loading...</Typography>
				) : engagements.length > 0 ? (
					<CustomAreaChart data={engagements} />
				) : (
					<Typography>No data available for the selected range.</Typography>
				)}
			</Box>
		</Box>
	);
};

export default EngagementChart;
