import SettingsStore from "@pages/Settings/store";
import WidgetsStore from "@pages/Widgets/store";

export interface IAppStore {
	settingsStore: SettingsStore;
	widgetsStore: WidgetsStore
}

export default class AppStore implements IAppStore {
	settingsStore: SettingsStore;
	widgetsStore: WidgetsStore;

	constructor() {
		this.settingsStore = new SettingsStore();
		this.widgetsStore = new WidgetsStore();
	}
}
