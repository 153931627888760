import ChartHeader from "@components/common/ChartHeader/ChartHeader";
import CustomPieChart from "@components/common/PieChart/PieChart";
import { useDateRange } from "@hooks/useDateRange";
import { Typography } from "@mui/material";
import AnalyticsService from "@services/AnalyticsService";
import React, { useEffect, useState } from "react";
import styles from "./ProductSentimentChart.module.scss";

interface ProductSentimentChartProps {
	previewOnly?: boolean;
	height?: number;
	legendPlacement?: "right" | "left";
	productId: number;
}

const ProductSentimentChart: React.FC<ProductSentimentChartProps> = ({
	previewOnly,
	height = 300,
	legendPlacement,
	productId
}) => {
	const analyticsService = new AnalyticsService();
	const { dateRange, handleDateChange } = useDateRange();
	const [sentimentData, setSentimentData] = useState<{ name: string; value: number }[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		loadSentimentData(dateRange[0], dateRange[1]);
	}, [dateRange, productId]);

	const loadSentimentData = async (startDate: Date, endDate: Date): Promise<void> => {
		setLoading(true);
		try {
			const response = await analyticsService.loadProductSentimentChart(startDate, endDate, productId);

			if (response) {
				const aggregatedData = response.reduce((acc: any, item: any) => {
					const existing = acc.find((entry: any) => entry.name === item.label);
					if (existing) {
						existing.value += item.value;
					} else {
						acc.push({ name: item.label, value: item.value });
					}
					return acc;
				}, []);

				setSentimentData(aggregatedData);
			}
		} catch (error) {
			console.error("Error fetching sentiment data:", error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className={styles.sentimentChartWrap}>
			<ChartHeader
				title="Sentiment Distribution"
				onDateChange={handleDateChange}
				value={dateRange}
				loading={loading}
				previewOnly={previewOnly}
			/>
			<div className={styles.blockContentWrap}>
				{loading ? (
					<Typography>Loading...</Typography>
				) : sentimentData.length > 0 ? (
					<CustomPieChart legendPlacement={legendPlacement} data={sentimentData} height={height} />
				) : (
					<Typography>No data available for the selected range.</Typography>
				)}
			</div>
		</div>
	);
};

export default ProductSentimentChart;
