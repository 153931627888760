import { makeAutoObservable } from "mobx";
import AuthStore, { IAuthStore } from "@pages/auth/store.tsx";
import AppStore, { IAppStore } from "@pages/store.tsx";
import WidgetsStore, { IWidgetsStore } from "@pages/Widgets/store.ts";
import UIStore, { IUIStore } from "../ui-store.tsx";
import VideosStore, { IVideosStore } from "@pages/Videos/store.ts";
import CreatorsStore, { ICreatorsStore } from "@pages/Creators/store.ts";
import ProductStore, { IProductStore } from "@pages/Video/productStore.ts";

export interface IRootStore {
	appStore: IAppStore;
	authStore: IAuthStore;
	uiStore: IUIStore;
	widgetsStore: IWidgetsStore;
	videosStore: IVideosStore;
	productStore: IProductStore;
	creatorsStore: ICreatorsStore;
}

export default class RootStore implements IRootStore {
	appStore = new AppStore() as IAppStore;
	authStore = new AuthStore() as IAuthStore;
	widgetsStore = new WidgetsStore() as IWidgetsStore;
	videosStore = new VideosStore() as IVideosStore;
	productStore = new ProductStore() as IProductStore;
	creatorsStore = new CreatorsStore() as ICreatorsStore;
	uiStore = new UIStore(this) as IUIStore;

    constructor() {
        makeAutoObservable(this);
    }
}