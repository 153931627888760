import SharedBubbleChart from "@components/common/BubbleChart/BubbleChart";
import ChartHeader from "@components/common/ChartHeader/ChartHeader";
import { useDateRange } from "@hooks/useDateRange";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import AnalyticsService from "@services/AnalyticsService";
import numeral from "numeral";
import { useEffect, useState } from "react";
import styles from "./CreatorsVideosCountsChart.module.scss";

const CreatorsVideosCountsChart = () => {
	const analyticsService = new AnalyticsService();
	const { dateRange, handleDateChange } = useDateRange();
	const [videosCount, setVideosCount] = useState<any[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		loadCreatorsVideosCountData(dateRange[0], dateRange[1]);
	}, [dateRange]);

	const loadCreatorsVideosCountData = async (startDate: Date, endDate: Date): Promise<void> => {
		setLoading(true);
		try {
			const response = await analyticsService.loadCreatorsVideosCountChart(startDate, endDate, 100);
			if (response) {
				const totalEngagements = response.reduce((sum, item: any) => sum + item.engagements, 0);
				const mappedData = response
					.map((item: any) => ({
						...item,
						engagementRate: ((item.engagements / totalEngagements) * 100).toFixed(4),
						name: `${item.nickname} @${item.username}`,
						// followers: numeral(item.followers).format("0a").toUpperCase()
					}))
					.sort((a, b) => b.followers - a.followers);
				setVideosCount(mappedData);
			}
		} catch (error) {
			console.error("Error fetching engagements data:", error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<ChartHeader
				title="Creators Videos Count"
				onDateChange={handleDateChange}
				value={dateRange}
				loading={loading}
			/>
			<Box className={styles.blockContentWrap}>
				{loading ? (
					<Typography>Loading...</Typography>
				) : videosCount.length > 0 ? (
					<SharedBubbleChart
						data={videosCount}
						xKey="totalVideos"
						yKey="engagementRate"
						zKey="followers"
						xLabel="Total Videos"
						yLabel="Engagement Rate (%)"
						zLabel="Followers"
					/>
				) : (
					<Typography>No data available for the selected range.</Typography>
				)}
			</Box>

			<TableContainer className={styles.tableContainer}>
				<Table stickyHeader>
					<TableHead className={styles.tableHeader}>
						<TableRow className={styles.tableRow}>
							<TableCell width={250}>Name</TableCell>
							<TableCell>Total Videos</TableCell>
							<TableCell>Followers</TableCell>
							<TableCell>Engagement Rate</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody className={styles.tableBody}>
						{videosCount?.map((product, index) => {
							return (
								<TableRow key={index} className={styles.tableRow}>
									<TableCell>
										{product.nickname} <span className={styles.username}>@{product.username}</span>
									</TableCell>
									<TableCell>{product.totalVideos}</TableCell>
									<TableCell>{numeral(product.followers).format("0a").toUpperCase()}</TableCell>

									<TableCell>
										<Box className={styles.cellLabel}>%{product.engagementRate}</Box>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default CreatorsVideosCountsChart;
