import RouterConstants from "@constants/RouterConstants";
import {
	AnalyticsRounded,
	FaceRounded,
	HomeRounded,
	InsightsRounded,
	KeyboardArrowDown,
	KeyboardArrowRight,
	MenuOpenRounded,
	MenuRounded,
	SensorOccupiedRounded,
	SettingsRounded,
	TroubleshootRounded,
	VideoLibraryRounded,
	WidgetsRounded,
} from "@mui/icons-material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import styles from "./SideBar.module.scss";

const drawerWidth: number = 220;
const itemList = [
	{ icon: <HomeRounded />, titleKey: "home", path: RouterConstants.HOME.path },
	{
		icon: <AnalyticsRounded />,
		titleKey: "insights",
		path: "/insights",
		submenu: [
			{ icon: <InsightsRounded />, titleKey: "recent_insights", path: RouterConstants.INSIGHTS.path },
			{
				icon: <SensorOccupiedRounded />,
				titleKey: "creators_insights",
				path: RouterConstants.CREATORS_INSIGHTS.path,
			},
			{
				icon: <TroubleshootRounded />,
				titleKey: "products_insights",
				path: RouterConstants.PRODUCTS_INSIGHTS.path,
			},
		],
	},
	{ icon: <WidgetsRounded />, titleKey: "widgets", path: RouterConstants.WIDGETS.path },
	{ icon: <VideoLibraryRounded />, titleKey: "videos", path: RouterConstants.VIDEOS.path },
	{ icon: <FaceRounded />, titleKey: "creators", path: RouterConstants.CREATORS.path },
	{ icon: <SettingsRounded />, titleKey: "settings", path: RouterConstants.SETTINGS.path },
];

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
	"& .MuiDrawer-paper": {
		position: "relative",
		whiteSpace: "nowrap",
		width: drawerWidth,
		padding: "4px 10px",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: "border-box",
		...(!open && {
			overflowX: "hidden",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: theme.spacing(6),
			[theme.breakpoints.up("xs")]: {
				width: theme.spacing(8),
			},
		}),
		borderRight: "none",
	},
}));

const CustomListItemIcon = styled(ListItemIcon)({
	color: "white",
	minWidth: 32,
	justifyContent: "center",
});

interface SideBarProps {
	open: boolean;
	toggleDrawer: () => void;
}

const SideBar: React.FC<SideBarProps> = ({ open, toggleDrawer }) => {
	const { t } = useTranslation("translation", { keyPrefix: "sidebar" });
	const { pathname } = useLocation();
	const [insightsOpen, setInsightsOpen] = useState<boolean>(false);

	const toggleInsights = () => {
		setInsightsOpen((prev) => !prev);
	};

	return (
		<Box className={styles.sideBarContainer}>
			<Drawer variant="permanent" open={open} className={styles.drawerContainer}>
				<List component="nav" className={styles.mainListContainer}>
					<Box className={styles.listItemRoot}>
						<Box className={styles.listItemContainer}>
							{itemList.map((item, index) => (
								<React.Fragment key={index}>
									{item.submenu ? (
										<Box className={styles.listItemButtonContainer}>
											<ListItemButton
												onClick={toggleInsights}
												className={`${styles.listItemButton} ${pathname.startsWith(item.path) ? styles.active : ""}`}
											>
												<CustomListItemIcon>{item.icon}</CustomListItemIcon>
												{open && (
													<ListItemText
														className={styles.listItemLabel}
														primary={t(item.titleKey)}
													/>
												)}
												<Box
													className={clsx(styles.arrowContainer, {
														[styles.collapsed]: !open,
													})}
												>
													{insightsOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
												</Box>
											</ListItemButton>
											<Collapse in={insightsOpen} timeout="auto" unmountOnExit>
												<List component="div" disablePadding>
													{item.submenu.map((subItem, subIndex) => (
														<Link
															to={subItem.path}
															key={subIndex}
															className={`${styles.listItemButtonContainer} ${
																pathname === subItem.path ? styles.active : ""
															}`}
														>
															<ListItemButton
																className={clsx(
																	styles.listItemButton,
																	styles.listSubItemButton,
																	{
																		[styles.collapsed]: !open,
																	},
																)}
															>
																<CustomListItemIcon>{subItem.icon}</CustomListItemIcon>
																{open && (
																	<ListItemText
																		className={styles.listItemLabel}
																		primary={t(subItem.titleKey)}
																	/>
																)}
															</ListItemButton>
														</Link>
													))}
												</List>
											</Collapse>
										</Box>
									) : (
										<Link
											to={item.path}
											className={`${styles.listItemButtonContainer} ${
												pathname === item.path ? styles.active : ""
											}`}
										>
											<ListItemButton className={styles.listItemButton}>
												<CustomListItemIcon>{item.icon}</CustomListItemIcon>
												{open && (
													<ListItemText
														className={styles.listItemLabel}
														primary={t(item.titleKey)}
													/>
												)}
											</ListItemButton>
										</Link>
									)}
								</React.Fragment>
							))}
						</Box>
						<Link
							onClick={toggleDrawer}
							className={`${styles.listItemButtonContainer} ${styles.collapseListItem}`}
							to={"#"}
						>
							<ListItemButton className={styles.listItemButton}>
								<CustomListItemIcon>{open ? <MenuOpenRounded /> : <MenuRounded />}</CustomListItemIcon>
								{open && <ListItemText className={styles.listItemLabel} primary={t("collapse")} />}
							</ListItemButton>
						</Link>
					</Box>
				</List>
			</Drawer>
		</Box>
	);
};

export default SideBar;
