import ChartHeader from "@components/common/ChartHeader/ChartHeader";
import { useDateRange } from "@hooks/useDateRange";
import {
	Box,
	LinearProgress,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import AnalyticsService from "@services/AnalyticsService";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import styles from "./TopProducts.module.scss";

interface TopProducsProps {
	previewOnly?: boolean;
}

const TopProducts: React.FC<TopProducsProps> = ({ previewOnly }) => {
	const analyticsService = new AnalyticsService();

	const { dateRange, handleDateChange } = useDateRange();
	const [productsData, setProductsData] = useState<{ name: string; value: number }[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		loadTopProducts(dateRange[0], dateRange[1]);
	}, [dateRange]);

	const loadTopProducts = async (startDate: Date, endDate: Date): Promise<void> => {
		setLoading(true);
		try {
			const response = await analyticsService.loadTopProductsChart(startDate, endDate);

			if (response) {
				// Aggregate sentiment data by label
				const aggregatedData = response.reduce((acc: any, item: any) => {
					const existing = acc.find((entry: any) => entry.name === item.product_id);
					if (existing) {
						existing.value += item.value;
					} else {
						acc.push({ name: item.product_name || item.product_id, value: item.total_engagements });
					}
					return acc;
				}, []);

				setProductsData(aggregatedData);
			}
		} catch (error) {
			console.error("Error fetching sentiment data:", error);
		} finally {
			setLoading(false);
		}
	};

	const maxEngagement = Math.max(...productsData.map((product) => product.value)) + 50;

	return (
		<Box className={styles.topProductsWrap}>
			<ChartHeader
				title="Top Products"
				onDateChange={handleDateChange}
				value={dateRange}
				loading={loading}
				previewOnly={previewOnly}
			/>

			{loading ? (
				<Typography>Loading...</Typography>
			) : productsData.length > 0 ? (
				<TableContainer>
					<Table>
						<TableHead className={styles.tableHeader}>
							<TableRow className={styles.tableRow}>
								<TableCell width={250}>Name</TableCell>
								<TableCell>Engagement</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody className={styles.tableBody}>
							{productsData.map((product, index) => {
								const engagementPercent = Math.round((product.value / maxEngagement) * 100);

								return (
									<TableRow key={index} className={styles.tableRow}>
										<TableCell>{product.name}</TableCell>
										<TableCell>
											<Box className={styles.progressBarContainer}>
												<LinearProgress
													variant="determinate"
													value={engagementPercent}
													className={styles.linearProgressBar}
													classes={{
														bar: styles.progressBar,
													}}
												/>
											</Box>
										</TableCell>
										<TableCell>
											<Box className={styles.cellLabel}>
												{numeral(product.value).format("0a").toUpperCase()}
											</Box>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<Typography>No data available for the selected range.</Typography>
			)}
		</Box>
	);
};

export default TopProducts;
