import { Box, CircularProgress } from "@mui/material";
import styles from "./Loader.module.scss";

interface IProps {
	loading: boolean;
	children?: any;
	className?: string;
}

const Loader = ({ loading, children, className }: IProps) => {
	return loading ? (
		<Box className={`${styles.loaderContainer} ${className}`}>
			<CircularProgress className={styles.loaderIcon}/>
		</Box>
	) : (
		children
	);
};

export default Loader;
