import RouterConstants from "@constants/RouterConstants";
import { InsightsRounded, SensorOccupiedRounded, TroubleshootRounded } from "@mui/icons-material";
import { Box } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import styles from "./InsightsSwitch.module.scss";

import { styled } from "@mui/material/styles";

const itemList = [
	{ icon: <InsightsRounded />, titleKey: "recent_insights", path: RouterConstants.INSIGHTS.path },
	{
		icon: <SensorOccupiedRounded />,
		titleKey: "creators_insights",
		path: RouterConstants.CREATORS_INSIGHTS.path,
	},
	{
		icon: <TroubleshootRounded />,
		titleKey: "products_insights",
		path: RouterConstants.PRODUCTS_INSIGHTS.path,
	},
];

const CustomListItemIcon = styled(ListItemIcon)({
	color: "white",
	minWidth: 32,
	justifyContent: "center",
});

const InsightsSwitch = () => {
	const { t } = useTranslation("translation", { keyPrefix: "sidebar" });
	const { pathname } = useLocation();

	return (
		<Box className={styles.insightsSwitchContainer}>
			<List component="div" disablePadding className={styles.insightsSwitchList}>
				{itemList.map((item, index) => (
					<Link
						to={item.path}
						key={index}
						className={`${styles.listItemButtonContainer} ${pathname === item.path ? styles.active : ""}`}
					>
						<ListItemButton
							className={clsx(styles.listItemButton, styles.listSubItemButton)}
						>
							<CustomListItemIcon>{item.icon}</CustomListItemIcon>
							<ListItemText className={styles.listItemLabel} primary={t(item.titleKey)} />
						</ListItemButton>
					</Link>
				))}
			</List>
		</Box>
	);
};

export default InsightsSwitch;
