import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

import InsightsSwitch from "@components/common/InsightsSwitch/InsightsSwitch";
import ProductPerformanceChart from "@components/productsInsights/ProductPerformanceChart/ProductPerformanceChart";
import ProductSentimentChart from "@components/productsInsights/ProductSentimentChart/ProductSentimentChart";
import { useAppStore } from "@hooks/useStores";
import { useState } from "react";
import styles from "./ProductsInsights.module.scss";

const ProductsInsights = () => {
	const { productStore } = useAppStore();
	const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
	const [searchResults, setSearchResults] = useState<any[]>([]);
	const [searchQuery, setSearchQuery] = useState("");
	const [loadingProductsSearch, setLoadingProductsSearch] = useState(false);
	const [loadingProducts, setLoadingProducts] = useState(false);

	const handleProductSelect = async (_event: any, value: any) => {
		if (value) {
			setSearchQuery("");
			try {
				setLoadingProducts(true);
				const response = await productStore.fetchProductsByIds({ productIds: [value?.id] });
				setSelectedProducts(response);
			} catch (error) {
				console.error("Error fetching product:", error);
			} finally {
				setLoadingProducts(false);
			}
		}
	};

	const searchProducts = async (query: any) => {
		setLoadingProductsSearch(true);
		productStore
			.searchProducts({ query: query || "" })
			.then((response: any) => {
				const result = (response?.products || []).map((product: any) => ({
					label: product?.name?.default ?? product.title,
					id: product?.id,
				}));

				setSearchResults(result);
			})
			.finally(() => {
				setLoadingProductsSearch(false);
			});
	};

	return (
		<>
			<Box className={styles.pageHeader}>
				<Typography className={styles.pageTitle} variant="h5">
					Products Insights
				</Typography>
				<InsightsSwitch />
			</Box>
			<Box className={styles.productsListWrapper}>
				<Autocomplete
					className={styles.productsListField}
					options={searchResults}
					getOptionLabel={(option: any) => option.label}
					onChange={handleProductSelect}
					inputValue={searchQuery}
					onInputChange={(_event, newInputValue) => {
						setSearchQuery(newInputValue);
						searchProducts(newInputValue);
					}}
					loading={loadingProducts || loadingProductsSearch}
					renderInput={(params) => (
						<TextField {...params} label="Type to search and select products" variant="outlined" />
					)}
				/>
			</Box>
			{selectedProducts.length > 0 ? (
				<>
					<Box className={styles.chartsRow}>
						{/* Engagement Overview */}

						<Box className={styles.BoxContainer}>
							<ProductPerformanceChart
								key={selectedProducts?.[0]?.id}
								productId={selectedProducts?.[0]?.id}
							/>
						</Box>

						{/* Sentiment Distribution */}
						<Box className={styles.BoxContainer}>
							<ProductSentimentChart productId={selectedProducts?.[0]?.id} />
						</Box>
					</Box>
				</>
			) : (
				<Box className={styles.emptyStatus}>
					<Typography variant="h4">Please select a product to show insights</Typography>
				</Box>
			)}
		</>
	);
};

export default observer(ProductsInsights);
