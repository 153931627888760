import QueryService from "@utils/QueryService.ts";

export interface LoginResponse {
	access_token: string;
	refresh_token: string;
}

class AuthService {
	queryService = new QueryService("/auth");

	login = async (email: string, password: string): Promise<LoginResponse> => {
		return await this.queryService.post("/login", { email, password });
	};

	refreshAccessToken = async (refreshToken: string): Promise<string> => {
		const response = await this.queryService.post("/refresh", null, {
			Authorization: `Bearer ${refreshToken}`,
		});
		return response.access_token;
	};
}

export default AuthService;
