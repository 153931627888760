export default class RouterConstants {
	public static readonly HOME = {
		path: "/",
	};
	public static readonly WIDGETS = {
		path: "/widgets",
	};
	public static readonly VIDEOS = {
		path: "/videos",
	};
	public static readonly INTEGRATIONS = {
		path: "/integrations",
	};
	public static readonly SETTINGS = {
		path: "/settings",
	};
	public static readonly LOGIN = {
		path: "/login",
	};
	public static readonly WIDGET = {
		path: "/widget",
	};
	public static readonly VIDEO = {
		path: "/video",
	};
	public static readonly INSIGHTS = {
		path: "/insights",
	};
	public static readonly CREATORS_INSIGHTS = {
		path: "/insights/creators",
	};
	public static readonly PRODUCTS_INSIGHTS = {
		path: "/insights/products",
	};
	public static readonly CREATORS = {
		path: "/creators",
	};
	public static readonly CREATOR = {
		path: "/creator",
	};
}
