import ConfirmationPopup from "@components/common/ConfirmationPopup";
import { useWidgetsLogic } from "@hooks/useWidgetsLogic";
import PageLayout from "@layouts/PageLayout";
import TableLayout from "@layouts/TableLayout/TableLayout";
import { Add } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import styles from "./Widgets.module.scss";

const Widgets = () => {
	const { t: ct } = useTranslation("translation");
	const { t } = useTranslation("translation", { keyPrefix: "widgets" });
	const { tableData, headerArray, createNewWidget, deleteBrand, closeDeletePopup, openDeletePopup, widgetsStore } =
		useWidgetsLogic();

	return (
		<PageLayout
			title={t("title")}
			actions={
				<Box className={styles.actionsHeader}>
					<Button variant="contained" onClick={createNewWidget} className={styles.actionBtn}>
						<Add /> {ct("common.createNew")}
					</Button>
				</Box>
			}
		>
			<Box className={styles.container}>
				<TableLayout
					// title={t("table.title")}
					headerArray={headerArray}
					tableData={tableData}
					hideSecondaryButton={true}
					loading={widgetsStore.isLoading}
				/>
				<ConfirmationPopup
					onClose={closeDeletePopup}
					title={t("deletePopup.title")}
					description={t("deletePopup.description")}
					confirmButtonText={ct("delete")}
					open={openDeletePopup}
					onClick={deleteBrand}
				/>
			</Box>
		</PageLayout>
	);
};

export default observer(Widgets);
