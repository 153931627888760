import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import styles from "./PieChart.module.scss";

interface SharedPieChartProps {
	data: { name: string; value: number }[];
	legendPlacement?: "right" | "left";
	height?: number;
	className?: string;
	legendClasses?: string;
}

const CustomizedLegend = (props: any) => {
	const { payload, legendPlacement, classes } = props;
	return (
		<Box
			className={clsx(
				styles.legendsList,
				{
					[styles.alignRight]: legendPlacement === "right",
				},
				classes,
			)}
		>
			{payload.map((entry: any, index: number) => (
				<Box key={index} className={styles.legendsItem}>
					<Box>
						<Box sx={{ backgroundColor: entry.payload.fill }} className={styles.bullet} />
					</Box>
					<Box className={styles.legendLabel}>
						<Typography className={styles.legendLabelText}>{entry.value}</Typography>
						<Typography className={styles.legendLabelValue}>{entry.payload.value}</Typography>
					</Box>
				</Box>
			))}
		</Box>
	);
};

const CustomPieChart: React.FC<SharedPieChartProps> = ({
	data,
	legendPlacement = "left",
	height = 300,
	className,
	legendClasses,
}) => {
	const getColor = (index: number) => `var(--color-${index % 10})`;

	return (
		<div className={clsx(styles.pieChartWrap, className)}>
			<ResponsiveContainer width="100%" height={height}>
				<PieChart>
					<Pie
						data={data}
						dataKey="value"
						nameKey="name"
						cx={legendPlacement === "right" ? "30%" : "50%"}
						cy="50%"
						outerRadius={100}
					>
						{data.map((_, index) => (
							<Cell key={`cell-${index}`} fill={getColor(index)} />
						))}
					</Pie>
					<Legend
						align={legendPlacement}
						content={<CustomizedLegend legendPlacement={legendPlacement} classes={legendClasses} />}
					/>
				</PieChart>
			</ResponsiveContainer>
		</div>
	);
};

export default CustomPieChart;
