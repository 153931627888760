import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CustomDateRangePicker from "../CustomDateRangePicker/CustomDateRangePicker";
import styles from "./ChartHeader.module.scss";

interface ChartHeaderProps {
	title: any;
	value: [Date, Date];
	onDateChange: (dateRange: [Date, Date]) => void;
	loading: boolean;
	previewOnly?: boolean;
}

const ChartHeader: React.FC<ChartHeaderProps> = ({ title, onDateChange, value, loading, previewOnly }) => {
	return (
		<Box className={styles.blockHead}>
			<Box className={styles.blockTitle}>
				<Typography variant="h6">{title}</Typography>
				<Box className={styles.datePickerContainer}>
					{previewOnly ? (
						<Link to="/insights" className={styles.vewMore}>
							<Typography>View more</Typography>
						</Link>
					) : (
						<CustomDateRangePicker cleanable value={value} onChange={onDateChange} loading={loading} />
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default ChartHeader;
