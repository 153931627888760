import MusicPlaceholder from "@assets/images/music-placeholder.png";
import CreatorInfo from "@components/common/CreatorInfo/CreatorInfo";
import CustomTabs from "@components/common/CustomTabs/CustomTabs";
import Loader from "@components/common/Loader";
import CustomPieChart from "@components/common/PieChart/PieChart";
import { IVideoAIViewProps } from "@models/videos.ts";
import {
	BlockRounded,
	CalendarMonthRounded,
	PersonRounded,
	ThumbDownRounded,
	ThumbsUpDownRounded,
	ThumbUpRounded,
	WarningAmberRounded,
	WavingHandRounded,
} from "@mui/icons-material";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { getFormattedDate } from "@utils/dateFormate";
import { getLanguageName } from "@utils/index";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CommentsTab from "./CommentsTab";
import ProductsTab from "./ProductsTab";
import styles from "./VideoAiView.module.scss";
import VideoStats from "./VideoStats";

interface SentimentIcons {
	positive: JSX.Element;
	negative: JSX.Element;
	sad: JSX.Element;
	neutral: JSX.Element;
	mixed: JSX.Element;
}

const sentimentsIcons: SentimentIcons = {
	positive: <ThumbUpRounded />,
	negative: <ThumbDownRounded />,
	sad: <ThumbDownRounded />,
	neutral: <WavingHandRounded />,
	mixed: <ThumbsUpDownRounded />,
};

const VideoAiView = ({ video, isLoading, products }: IVideoAIViewProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "videos.table.videoAiView" });
	const [rawData, setRawData] = useState<any>();
	const [sentimentAnalysisData, setSentimentAnalysisData] = useState<any>();
	const [comments, setComments] = useState<any[]>([]);
	const [isExpanded, setIsExpanded] = useState(false);
	const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
	const [authorId, setAuthorId] = useState<string | null>(null);
	const toggleExpand = () => setIsExpanded(!isExpanded);
	const [emotionSummary, setEmotionSummary] = useState<{ name: string; value: number }[]>();

	const getFirstThreeLines = (text: string, maxCharsPerLine = 200) => {
		const virtualLines = [];

		for (let i = 0; i < text.length; i += maxCharsPerLine) {
			virtualLines.push(text.slice(i, i + maxCharsPerLine));
			if (virtualLines.length === 3) break;
		}

		return virtualLines.join("\n");
	};

	useEffect(() => {
		getMetaData();
	}, [video?.meta_data, authorId, video]);

	const formattedDate = getFormattedDate(rawData?.createTime * 1000);

	const getMetaData = async () => {
		if (video && video?.meta_data && video?.meta_data?.length > 0) {
			const commentsArr: any[] = [];
			for (const metaData of video.meta_data) {
				if (metaData.type === "RAW") {
					const rowData = JSON.parse(metaData.value ?? "");
					setRawData(rowData);
					if (rowData?.author?.id) {
						setAuthorId(rowData.author.id);
					}
				} else if (metaData.type === "COMMENT") {
					commentsArr.push(JSON.parse(metaData.value ?? ""));
				} else if (metaData.type === "EMOTION_ANALYSIS_RESULT") {
					const emotionData = JSON.parse(metaData.value ?? "");
					const emotionSummary: { name: string; value: number }[] = Object.entries(emotionData.summary).map(
						([key, value]) => ({
							name: key,
							value: parseInt(value as string),
						}),
					);
					setEmotionSummary(emotionSummary);
				} else if (metaData.type === "SENTIMENT_ANALYSIS_RESULT") {
					setSentimentAnalysisData(JSON.parse(metaData.value ?? ""));
				}
			}
			setComments(commentsArr);
		}
	};

	const tabContent = [
		{
			label: t("products"),
			content: <ProductsTab products={products} video={video} />,
		},
		{
			label: t("comments"),
			content: <CommentsTab comments={comments} />,
		},
	];

	const sentiment = Array.isArray(sentimentAnalysisData?.sentiment)
		? sentimentAnalysisData?.sentiment[0]
		: sentimentAnalysisData?.sentiment;

	return (
		<Box className={styles.container}>
			<Loader loading={isLoading || !rawData}>
				{video?.meta_data && video.meta_data.length > 0 ? (
					<Box className={styles.videoContent}>
						<Box className={styles.leftSection}>
							{rawData && (
								<Box className={styles.videoInfoWrapper}>
									{/* Sentiment Analysis Section */}
									<Typography className={styles.sectionTitle}>
										{t("aiAnalytics")}:
										{sentimentAnalysisData?.language !== "en" && (
											<Tooltip
												title={t("aiAnalyticsWarning")}
												placement="top"
												arrow
												componentsProps={{
													tooltip: {
														className: clsx(styles.tooltip),
													},
													arrow: {
														className: clsx(styles.arrow),
													},
												}}
											>
												<WarningAmberRounded className={styles.warningIcon} />
											</Tooltip>
										)}
									</Typography>
									<Box className={clsx(styles.rowData, styles.aiAnalyticsSection)}>
										<Box>
											<Typography className={styles.infoLabel}>
												{t("sentimentAnalysisExplanation")}:
											</Typography>
											<Typography className={styles.infoValue}>
												{sentimentAnalysisData?.explanation ||
													"No sentiment analytics available"}
											</Typography>
											{sentimentAnalysisData?.product && (
												<Box className={styles.productContainer}>
													<Typography className={styles.infoLabel}>
														{t("detectedProduct")}:
													</Typography>
													<Typography className={styles.infoValue}>
														{sentimentAnalysisData.product}
													</Typography>
												</Box>
											)}
											{sentimentAnalysisData && (
												<>
													<Box className={styles.productContainer}>
														<Typography className={styles.infoLabel}>
															{t("relevance")}:
														</Typography>
														<Typography className={styles.infoValue}>
															{`${sentimentAnalysisData?.relevance}`}
														</Typography>
													</Box>
													<Box className={styles.productContainer}>
														<Typography className={styles.infoLabel}>
															{t("lyrics")}:
														</Typography>
														<Typography className={styles.infoValue}>
															{`${sentimentAnalysisData?.lyrics}`}
														</Typography>
													</Box>
												</>
											)}

											<Box
												className={clsx(
													styles.analyticsCart,
													styles[
														`${Array.isArray(sentimentAnalysisData?.sentiment) ? sentimentAnalysisData?.sentiment[0] : sentimentAnalysisData?.sentiment}`
													],
												)}
											>
												{sentiment ? (
													sentimentsIcons[sentiment as keyof SentimentIcons]
												) : (
													<BlockRounded />
												)}
												<Box>
													<Typography className={styles.cardLabel}>
														{t("sentimentAnalysisResult")}
													</Typography>
													<Typography className={styles.cardValue}>
														{sentiment
															? Array.isArray(sentimentAnalysisData?.sentiment)
																? sentimentAnalysisData?.sentiment[0]
																: sentimentAnalysisData?.sentiment
															: t("none")}
													</Typography>
												</Box>
											</Box>
										</Box>
										{/* Emotion Analysis */}
										<Box className={styles.analyticsCards}>
											{emotionSummary && (
												<Box className={styles.emotionSummaryChart} sx={{ width: 380 }}>
													<CustomPieChart
														data={emotionSummary}
														height={220}
														legendPlacement="right"
														legendClasses={styles.emotionSummaryChartLegends}
													/>
													<Typography className={styles.emotionAnalysisLabel}>
														{t("emotionAnalysisData")}
													</Typography>
												</Box>
											)}
										</Box>
									</Box>

									{/* Transcript Section */}

									<Box sx={{ margin: "2rem 0" }}>
										<Box className={styles.sentimentAnalysisData}>
											<Typography className={styles.infoLabel}>
												{t("transcript")}
												{sentimentAnalysisData?.language && (
													<span className={styles.langLabel}>
														{getLanguageName(sentimentAnalysisData.language)}
													</span>
												)}
											</Typography>
											{sentimentAnalysisData && sentimentAnalysisData.text ? (
												<>
													<Typography className={clsx(styles.infoValue, styles.transcript)}>
														{isExpanded || sentimentAnalysisData.text?.length < 200
															? sentimentAnalysisData.text
															: `${getFirstThreeLines(sentimentAnalysisData.text)}...`}
													</Typography>
													{sentimentAnalysisData.text.length > 300 && (
														<Button onClick={toggleExpand} className={styles.expandBtn}>
															{isExpanded ? t("collapse") : t("expand")}
														</Button>
													)}
												</>
											) : <Typography  className={styles.infoValue}>No Transcript available</Typography>}
										</Box>
									</Box>

									{/* TikToker Information */}
									<Typography className={styles.sectionTitle}>{t("tiktokerInformation")}:</Typography>
									{authorId && <CreatorInfo creatorId={authorId} />}
								</Box>
							)}

							{/* Tabs */}
							<CustomTabs tabs={tabContent} />
						</Box>
						<Box className={styles.rightSection}>
							{rawData && (
								<Box>
									<Box className={styles.videoInfoData}>
										<Box>
											<Box className={styles.innerBox}>
												<Box className={styles.videoWrapper}>
													<video src={video.url} controls />
													{rawData?.stats && <VideoStats stats={rawData?.stats} t={t} />}
												</Box>
											</Box>
											<Box className={styles.innerBox}>
												<Typography className={styles.infoLabel}>{t("description")}</Typography>
												<Typography className={styles.infoValue}>
													{isDescriptionExpanded || rawData?.desc.length < 50
														? rawData?.desc
														: `${getFirstThreeLines(rawData?.desc, 50)}...`}
													<Button
														onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
														className={styles.expandBtn}
													>
														{isDescriptionExpanded ? t("collapse") : t("expand")}
													</Button>
												</Typography>

												<Box className={styles.infoRow}>
													{formattedDate && (
														<Typography className={styles.infoRow}>
															<span className={styles.infoLabel}>{t("postedOn")}: </span>
															<span className={clsx(styles.infoValue, styles.withIcon)}>
																<CalendarMonthRounded /> {formattedDate}
															</span>
														</Typography>
													)}

													<Box className={styles.statusStyle}>
														<Typography
															className={
																video.status === "PENDING"
																	? styles.pendingStatus
																	: video.status === "SKIPPED"
																		? styles.skippedStatus
																		: styles.readyStatus
															}
														>
															{video.status === "SKIPPED" ? t("skipped") : video.status}
														</Typography>
													</Box>
												</Box>
											</Box>
											{rawData?.music && (
												<Box className={styles.innerBox}>
													<Typography className={styles.bold}>
														{t("musicInformation")}:
													</Typography>
													<Box className={styles.musicInfo}>
														<Box className={styles.musicThumbnail}>
															<img
																src={rawData?.music?.coverThumb || MusicPlaceholder}
																alt="Music Thumbnail"
																onError={(e) => {
																	(e.target as HTMLImageElement).src =
																		MusicPlaceholder;
																}}
															/>
														</Box>
														<Box className={styles.musicContent}>
															<Typography className={styles.musicTitle}>
																{rawData?.music?.title}
															</Typography>
															<Typography className={styles.authorName}>
																<PersonRounded /> {rawData?.music?.authorName}
															</Typography>
															<Typography
																className={clsx(styles.label, {
																	[styles.original]: rawData?.music?.original,
																	[styles.notOriginal]: !rawData?.music?.original,
																})}
															>
																{rawData?.music?.original
																	? t("original")
																	: t("notOriginal")}
															</Typography>
														</Box>
													</Box>
												</Box>
											)}
										</Box>
									</Box>
								</Box>
							)}
						</Box>
					</Box>
				) : (
					<>{t("aiDataNotFound")}</>
				)}
			</Loader>
		</Box>
	);
};

export default observer(VideoAiView);
