import "@assets/styles/main.scss";
import RouterConstants from "@constants/RouterConstants.ts";
import AppLayout from "@layouts/AppLayout/AppLayout";
import AuthLayout from "@layouts/AuthLayout/AuthLayout";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Login from "@pages/auth/Login/Login";
import Creator from "@pages/Creator/Creator";
import Creators from "@pages/Creators/Creators";
import CreatorsInsights from "@pages/CreatorsInsights/CreatorsInsights";
import Home from "@pages/Home/Home.tsx";
import Insights from "@pages/Insights/Insights";
import Integrations from "@pages/Integrations/Integrations";
import ProductsInsights from "@pages/ProductsInsights/ProductsInsights";
import Settings from "@pages/Settings/Settings";
import Video from "@pages/Video/Video";
import Videos from "@pages/Videos/Videos";
import Widget from "@pages/Widget/Widget";
import Widgets from "@pages/Widgets/Widgets";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.scss";

// Function to fetch CSS variables from :root
const getCSSVariable = (variableName: string): string => {
	return getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();
};

// Function to create a theme dynamically
const generateTheme = () => {
	const primaryColor = getCSSVariable("--primary") || "#1f35c4";

	return createTheme({
		palette: {
			primary: {
				main: primaryColor,
			},
		},
		typography: {
			fontFamily: "Helvetica, sans-serif",
		},
	});
};

function App() {
	const theme = generateTheme();

	return (
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<Routes>
					<Route element={<AppLayout />}>
						<Route path={RouterConstants.HOME.path} element={<Home />} />
						<Route path={RouterConstants.INSIGHTS.path} element={<Insights />} />
						<Route path={RouterConstants.CREATORS_INSIGHTS.path} element={<CreatorsInsights />} />
						<Route path={RouterConstants.PRODUCTS_INSIGHTS.path} element={<ProductsInsights />} />
						<Route path={RouterConstants.WIDGETS.path} element={<Widgets />} />
						<Route path={`${RouterConstants.WIDGET.path}/:id?`} element={<Widget />} />
						<Route path={RouterConstants.VIDEOS.path} element={<Videos />} />
						<Route path={RouterConstants.CREATORS.path} element={<Creators />} />
						<Route path={`${RouterConstants.CREATOR.path}/:id`} element={<Creator />} />
						<Route path={`${RouterConstants.VIDEO.path}/:id?`} element={<Video />} />
						<Route path={RouterConstants.INTEGRATIONS.path} element={<Integrations />} />
						<Route path={RouterConstants.SETTINGS.path} element={<Settings />} />
					</Route>
					<Route element={<AuthLayout />}>
						<Route path={RouterConstants.LOGIN.path} element={<Login />} />
					</Route>
				</Routes>
			</BrowserRouter>
			<ToastContainer />
		</ThemeProvider>
	);
}

export default App;
