import { useState } from "react";
import { showSuccessToast } from "../utils/toast";

interface IParams {
	toastId?: string;
}

const useSuccessToast = (params: IParams = {}) => {
	const [toastId, setToastId] = useState<string | undefined>(params.toastId);

	function showSuccess(successMessage?: string) {
		const taostId = showSuccessToast(successMessage, toastId);
		setToastId(taostId);
	}

	return {
		showSuccess,
		toastId,
	};
};

export default useSuccessToast;
