import { Creator } from "@models/videos";
import QueryService from "@utils/QueryService.ts";
import { makeAutoObservable, runInAction } from "mobx";

export interface ICreatorsStore {
	totalRows?: number;
	creators: any[];
	isError: boolean;
	isLoading: boolean;

	getCreators({ perPage, page, username }: { perPage?: number; page?: number; username?: string }): Promise<void>;

	getCreator(videoId: string): Promise<Creator>;

	updateVideo(videoId: string, videoData: any): Promise<void>;

	deleteVideo(videoId: string): Promise<void>;

	filters: any;

	setFilter(key: string, value: any): void;
}

export default class CreatorsStore implements ICreatorsStore {
	queryService = new QueryService("/tiktoker");
	creators: any[] = [];
	totalRows?: number = 0;
	isError: boolean = false;
	isLoading: boolean = false;

	filters: any = {
		tiktokerName: "",
		status: "all",
		sentiment: "all",
		emotion: "all",
		postedAt: undefined as [Date, Date] | undefined,
		updatedAt: undefined as [Date, Date] | undefined,
	};

	setFilter = (key: string, value: any) => {
		this.filters[key] = value;
	};


	constructor() {
		makeAutoObservable(this);
	}

	getCreators = async ({
		perPage = 10,
		page = 1,
		username = "",
	}: {
		perPage?: number;
		page?: number;
		username?: string;
	}): Promise<void> => {
		try {
			this.isError = false;
			this.isLoading = true;
			const response = await this.queryService.get(
				`/list?page_size=${perPage}&page_number=${page}&username=${username || this.filters.tiktokerName}`,
			);
			runInAction(() => {
				this.creators = response.items;
				this.totalRows = response.total;
				this.isError = false;
				this.isLoading = false;
			});
		} catch (error) {
			console.error("Error fetching videos:", error);
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
		}
	};

	getCreator = async (creatorId: string): Promise<Creator> => {
		try {
			this.isError = false;
			// this.isLoading = true;
			const creator = await this.queryService.get(`/${creatorId}`);
			return creator;
		} catch (error) {
			console.error("Error fetching video:", error);
			runInAction(() => {
				// this.isLoading = false;
				this.isError = true;
			});
			throw error;
		}
	};

	updateVideo = async (videoId: string, videoData: any): Promise<void> => {
		try {
			this.isError = false;
			this.isLoading = true;
			await this.queryService.put(`/${videoId}`, videoData);
			runInAction(() => {
				this.isLoading = false;
			});
		} catch (error) {
			console.error("Error updating video:", error);
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			throw error;
		}
	};

	deleteVideo = async (videoId: string): Promise<void> => {
		try {
			this.isError = false;
			this.isLoading = true;

			await this.queryService.delete(`/${videoId}`);
			runInAction(() => {
				this.isLoading = false;
			});
		} catch (error) {
			console.error("Error deleting video:", error);
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
		}
	};
}
