import Header from "@components/common/Header";
import Loader from "@components/common/Loader";
import SideBar from "@components/common/SideBar";
import { useAppStore } from "@hooks/useStores.tsx";
import { Box } from "@mui/material";
import { isLoggedIn } from "@utils/middleware.ts";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import styles from "./AppLayout.module.scss";

const AppLayout: React.FC = () => {
	const { authStore } = useAppStore();
	const [open, setOpen] = useState(false);
	const toggleDrawer = () => {
		setOpen(!open);
	};

	if (!isLoggedIn()) {
		return <Navigate to="/login" />;
	}

	if (!authStore.user) {
		authStore.fetchUserInfo();
	}

	return (
		<Loader loading={!authStore.user} className={styles.loader}>
			<>
				<Header />
				<div className={styles.container}>
					<SideBar open={open} toggleDrawer={toggleDrawer} />
					<Box className={styles.pageWrapper}>
						<Outlet />
					</Box>
				</div>
			</>
		</Loader>
	);
};

export default observer(AppLayout);
