import RouterConstants from "@constants/RouterConstants";
import { defaultWidget } from "@constants/WidgetConstants";
import useErrorToast from "@hooks/useErrorToast";
import { useAppStore } from "@hooks/useStores";
import environmentStore from "@mobx/EnvironmentStore";
import { Video } from "@models/videos";
import { Widget as WidgetInterface } from "@models/widgets";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const useWidgetLogic = () => {
	const location = useLocation();
	const [widgetData, setWidgetData] = useState<WidgetInterface>(location.state?.WidgetToEdit ?? defaultWidget);
	const [selectedCustomizationType, setSelectedCustomizationType] = useState<string>("");
	const [customizationValue, setCustomizationValue] = useState<string>("");
	const { widgetsStore } = useAppStore();
	const { videosStore } = useAppStore();
	const [videosList, setVideosList] = useState<Video[]>([]);
	const [openVideoModal, setOpenVideoModal] = useState(false);
	const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);
	const { id } = useParams();
	const navigate = useNavigate();
	const { showError } = useErrorToast();
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const perPage = 10;
	const [hasMore, setHasMore] = useState(true);

	const loadMoreData = useCallback(() => {
		if (!loading && hasMore) {
			// setLoading(true);
			fetchMoreData(page);
			setPage((prevPage) => prevPage + 1);
		}
	}, [loading, hasMore, page]);

	const fetchMoreData = useCallback(
		(page: number) => {
			videosStore
				.getVideos({ page: page + 1, perPage })
				.then(() => {
					if (videosStore.videos.length > 0) {
						setVideosList((prevData) => [...prevData, ...videosStore.videos]);
					} else {
						setHasMore(false);
					}
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
				})
				.finally(() => {
					setLoading(false);
				});
		},
		[videosStore, perPage],
	);

	const [sentryRef] = useInfiniteScroll({
		loading,
		hasNextPage: hasMore,
		onLoadMore: loadMoreData,
		rootMargin: "0px 0px 200px 0px",
	});

	const debouncedHandleSearchVideos = debounce((_e: any, query: string) => {
		videosStore.getVideos({ query }).then(() => {
			setPage(1);
			setVideosList(videosStore.videos);
		});
	}, 500);

	const handleCustomizationAdd = () => {
		if (selectedCustomizationType && customizationValue) {
			setWidgetData({
				...widgetData,
				customizations: [
					...widgetData.customizations,
					{ type: selectedCustomizationType, value: customizationValue },
				],
			});
			setSelectedCustomizationType("");
			setCustomizationValue("");
		}
	};

	const handleVideoDeselect = (videoId: string) => {
		setWidgetData({ ...widgetData, videos: widgetData.videos.filter((video) => video.id !== videoId) });
	};

	const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (widgetData?.id) {
			const dataToSend = {
				...widgetData,
				environment: environmentStore.environment,
				videos: [...widgetData.videos.map((video) => ({ id: video.id }))],
			};
			widgetsStore
				.updateWidget(widgetData.id, dataToSend)
				.then(() => {
					navigate(RouterConstants.WIDGETS.path);
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			const dataCreated = {
				...widgetData,
				environment: environmentStore.environment,
				videos: [...widgetData.videos.map((video) => ({ id: video.id }))],
			};

			widgetsStore
				.createWidget(dataCreated)
				.then(() => {
					navigate(RouterConstants.WIDGETS.path);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};

	const handleTemplateChange = (template: any) => {
		setWidgetData({ ...widgetData, template });
	};

	const handleDeleteCustomization = (customization: any) => {
		setWidgetData({
			...widgetData,
			customizations: widgetData.customizations.filter((item) => item.value !== customization.value),
		});
	};

	const handleCancel = () => {
		navigate(RouterConstants.WIDGETS.path);
	};

	const getVideosList = () => {
		setLoading(true);
		videosStore
			.getVideos({})
			.then(() => {
				setVideosList(videosStore.videos.map((video) => video));
			})
			.finally(() => setLoading(false));
	};

	const handleSelectVideo = (_e: any, video: any) => {
		const selectedVideo = { ...video };
		if (!widgetData?.videos.some((item) => item.id === selectedVideo.id) && selectedVideo.id) {
			setWidgetData({
				...widgetData,
				videos: [...widgetData.videos, selectedVideo],
			});
		}
	};

	const handleOpenVideoPopup = (video: Video) => {
		setSelectedVideo(video);
		setOpenVideoModal(true);
	};

	const closeVideoPopup = () => {
		setSelectedVideo(null);
		setOpenVideoModal(false);
	};

	useEffect(() => {
		getVideosList();
		if (id) {
			widgetsStore
				.getWidget(id ?? "")
				.then((widget) => {
					setWidgetData(widget);
				})
				.catch((error) => {
					setWidgetData(defaultWidget);
					showError(error?.response?.data?.message);
				});
		}
	}, [environmentStore.environment]);

	return {
		widgetData,
		setWidgetData,
		selectedCustomizationType,
		setSelectedCustomizationType,
		customizationValue,
		setCustomizationValue,
		videosList,
		setVideosList,
		openVideoModal,
		setOpenVideoModal,
		selectedVideo,
		loading,
		handleFormSubmit,
		handleTemplateChange,
		handleDeleteCustomization,
		handleCancel,
		handleCustomizationAdd,
		handleVideoDeselect,
		handleSelectVideo,
		handleOpenVideoPopup,
		closeVideoPopup,
		debouncedHandleSearchVideos,
		hasMore,
		sentryRef,
	};
};
