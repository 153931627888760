import InsightsSwitch from "@components/common/InsightsSwitch/InsightsSwitch";
import CreatorsEngagements from "@components/creatorsInsights/CreatorsEngagements/CreatorsEngagements";
import CreatorsVideosCountsChart from "@components/creatorsInsights/CreatorsVideosCountsChart/CreatorsVideosCountsChart";
import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import styles from "./CreatorsInsights.module.scss";

const CreatorsInsights = () => {
	return (
		<>
			<Box className={styles.pageHeader}>
				<Typography className={styles.pageTitle} variant="h5">
					Creators Insights
				</Typography>
				<InsightsSwitch />
			</Box>
			<Box className={styles.chartsRow}>
				{/* Engagement Overview */}

				<Box className={styles.BoxContainer}>
					<CreatorsEngagements />
				</Box>

				{/* Sentiment Distribution */}
				<Box className={styles.BoxContainer}>
					<CreatorsVideosCountsChart />
				</Box>
			</Box>
		</>
	);
};

export default observer(CreatorsInsights);
