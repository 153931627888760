import { useVideosLogic } from "@hooks/useVideosLogic";
import { InsightsRounded } from "@mui/icons-material";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { IconButton } from "rsuite";
import styles from "./VideoCard.module.scss";

interface VideoCardProps {
	thumbnail: string | undefined;
	description?: string;
	id: string;
	video?: any;
}

const VideoCard: React.FC<VideoCardProps> = ({ thumbnail, description, video }) => {
	const { handleModifyVideo } = useVideosLogic();

	const goToVideo = () => {
		handleModifyVideo(video);
	};

	return (
		<Box className={styles.videoCartContainer}>
			<CardMedia
				component="img"
				className={styles.thumbnail}
				image={thumbnail}
				alt="Live from space album cover"
			/>
			<Box className={styles.cardContentWrapper}>
				<CardContent className={styles.cardContent}>
					<Typography variant="subtitle1" component="p" className={styles.videoDescription}>
						{description}
					</Typography>
				</CardContent>
				<Box className={styles.actions}>
					<IconButton className={styles.actionBtn} onClick={goToVideo}>
						<InsightsRounded />
					</IconButton>
				</Box>
			</Box>
		</Box>
	);
};

export default VideoCard;
