import CustomDateRangePicker from "@components/common/CustomDateRangePicker/CustomDateRangePicker";
import DebouncedInput from "@components/common/DebouncedInput/DebouncedInput";
import { useAppStore } from "@hooks/useStores";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./Videos.module.scss";

const CustomFilters = ({ onFilterChange }: { onFilterChange: () => void }) => {
	const { t } = useTranslation("translation", { keyPrefix: "videos.table" });
	const { videosStore } = useAppStore();

	const handleFilterChange = (key: string, value: string) => {
		videosStore.setFilter(key, value);
		onFilterChange();
	};

	return (
		<Box className={styles.customFilters}>
			{/* Tiktoker Name Input */}
			<DebouncedInput
				label={t("filters.tiktokerName")}
				variant="outlined"
				value={videosStore.filters.tiktokerName}
				onChange={(e) => handleFilterChange("tiktokerName", e.target.value)}
				className={styles.filterInput}
				sx={{
					width: "200px",
					"& .MuiInputLabel-root": {
						transform: "translate(14px, 10px) scale(1)",
						fontSize: 14,
					},
					"& .MuiInputLabel-shrink": {
						transform: "translate(14px, -6px) scale(0.75)",
					},
					"& .MuiOutlinedInput-input": {
						padding: "8px 14px",
						height: "24px",
					},
				}}
			/>

			{/* Status Dropdown */}
			<FormControl>
				<InputLabel id="status-label">{t("filters.status")}</InputLabel>
				<Select
					value={videosStore.filters.status}
					onChange={(e) => handleFilterChange("status", e.target.value)}
					className={styles.filterInput}
					labelId="status-select-label"
					id="status-select"
					label={t("filters.status")}
				>
					<MenuItem value="all">{t("filters.all")}</MenuItem>
					<MenuItem value="PENDING">{t("filters.pending")}</MenuItem>
					<MenuItem value="SKIPPED">{t("filters.skipped")}</MenuItem>
					<MenuItem value="READY">{t("filters.ready")}</MenuItem>
				</Select>
			</FormControl>

			{/* Sentiment Dropdown */}
			<FormControl>
				<InputLabel id="sentiment-label">{t("filters.sentiment")}</InputLabel>
				<Select
					value={videosStore.filters.sentiment}
					onChange={(e) => handleFilterChange("sentiment", e.target.value)}
					className={styles.filterInput}
					labelId="sentiment-select-label"
					id="sentiment-select"
					label={t("filters.sentiment")}
				>
					<MenuItem value="all">{t("filters.all")}</MenuItem>
					<MenuItem value="positive">{t("filters.positive")}</MenuItem>
					<MenuItem value="neutral">{t("filters.neutral")}</MenuItem>
					<MenuItem value="negative">{t("filters.negative")}</MenuItem>
				</Select>
			</FormControl>

			{/* Emotion Dropdown */}
			<FormControl>
				<InputLabel id="emotion-label">{t("filters.emotion")}</InputLabel>
				<Select
					value={videosStore.filters.emotion}
					onChange={(e) => handleFilterChange("emotion", e.target.value)}
					className={styles.filterInput}
					labelId="emotion-select-label"
					id="emotion-select"
					label={t("filters.emotion")}
				>
					<MenuItem value="all">{t("filters.all")}</MenuItem>
					<MenuItem value="happy">{t("filters.happy")}</MenuItem>
					<MenuItem value="neutral">{t("filters.neutral")}</MenuItem>
					<MenuItem value="angry">{t("filters.angry")}</MenuItem>
					<MenuItem value="sad">{t("filters.sad")}</MenuItem>
					<MenuItem value="fear">{t("filters.fear")}</MenuItem>
					<MenuItem value="surprise">{t("filters.surprise")}</MenuItem>
				</Select>
			</FormControl>

			{/* Posted At Date Range Picker */}
			<CustomDateRangePicker
				value={videosStore.filters.postedAt}
				onChange={(value) => handleFilterChange("postedAt", value)}
				cleanable
				className={styles.datePicker}
				label={t("filters.posted_at")}
				labelClassName={styles.datePickerLabel}
				isRangePicker={false}
				disabled
			/>

			{/* Updated At Date Range Picker */}
			<CustomDateRangePicker
				value={videosStore.filters.updatedAt}
				onChange={(value) => handleFilterChange("updatedAt", value)}
				cleanable
				className={styles.datePicker}
				label={t("filters.updated_at")}
				labelClassName={styles.datePickerLabel}
				isRangePicker={false}
				disabled
			/>
		</Box>
	);
};

export default CustomFilters;
