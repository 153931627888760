import SliderVideo from "@assets/images/svgs/SliderVideo.svg";
import FloatingVideo from "@assets/images/svgs/StoryVideo.svg";
import { modalStyles } from "@assets/styles/ModalStyles";
import Loader from "@components/common/Loader";
import VideoPlayer from "@components/common/VideoPlayer/VideoPlayer";
import { WidgetTemplates, WidgetTypes } from "@constants/WidgetConstants";
import { useWidgetLogic } from "@hooks/useWidgetLogic";
import PageLayout from "@layouts/PageLayout";
import { Widget as WidgetInterface } from "@models/widgets";
import { DeleteOutline } from "@mui/icons-material";
import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	Modal,
	OutlinedInput,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import styles from "./Widget.module.scss";

export type EditWidgetState = {
	WidgetToEdit?: WidgetInterface;
};

const Widget = () => {
	const {
		loading,
		widgetData,
		setWidgetData,
		videosList,
		openVideoModal,
		selectedVideo,
		handleFormSubmit,
		handleTemplateChange,
		handleCancel,
		handleVideoDeselect,
		handleSelectVideo,
		handleOpenVideoPopup,
		closeVideoPopup,
		debouncedHandleSearchVideos,
		hasMore,
		sentryRef,
	} = useWidgetLogic();

	const { t: ct } = useTranslation("translation");
	const { t } = useTranslation("translation", { keyPrefix: "widgets" });

	const renderTemplateIcon = (template: WidgetTemplates) => {
		switch (template) {
			case WidgetTemplates.slider:
				return <img className={styles.templateIcon} src={SliderVideo} />;
			case WidgetTemplates.stories:
				return <img className={styles.templateIcon} src={FloatingVideo} />;
			default:
				return null;
		}
	};

	return (
		<PageLayout title={widgetData?.id ? widgetData?.name : t("newWidget")} showBackBtn>
			<Box className={styles.container}>
				<div>
					<form onSubmit={handleFormSubmit}>
						<Grid container spacing={5}>
							<Grid item lg={6} sm={12}>
								<Grid container spacing={3}>
									<Grid item lg={6} md={6} sm={12}>
										<FormControl className={styles.formControl}>
											<TextField
												label={t("labels.name")}
												id="name"
												value={widgetData.name}
												onChange={(e) =>
													setWidgetData({ ...widgetData, name: e.target.value as string })
												}
											/>
										</FormControl>
									</Grid>
									<Grid item lg={6} md={6} sm={12}>
										<FormControl className={styles.formControl}>
											<InputLabel id="type-label">{t("labels.type")}</InputLabel>
											<Select
												labelId="type-label"
												value={widgetData.type}
												input={<OutlinedInput label="Type" />}
												onChange={(e) =>
													setWidgetData({ ...widgetData, type: e.target.value as string })
												}
											>
												{Object.values(WidgetTypes).map((type) => (
													<MenuItem key={type} value={type}>
														{t(`types.${type}`)}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item lg={12} md={12} sm={12}>
										<TextField
											id="description"
											label={t("labels.description")}
											className={styles.formControl}
											value={widgetData.description}
											onChange={(e) =>
												setWidgetData({ ...widgetData, description: e.target.value })
											}
											multiline
											rows={4}
										/>
									</Grid>
									<Grid item lg={6} md={4} sm={12}>
										<FormControl component="fieldset" className={styles.formControl}>
											<Typography variant="body1">{t("labels.template")}:</Typography>
											<div className={styles.templateIconsWrapper}>
												{Object.values(WidgetTemplates).map((template) => (
													<FormControlLabel
														key={template}
														control={<Checkbox style={{ display: "none" }} />}
														className={styles.formControl}
														label={
															<div
																className={clsx(styles.templateIconContainer, {
																	[styles.active]: widgetData.template === template,
																})}
															>
																{renderTemplateIcon(template)}
																<span>{t(`templates.${template}`)}</span>
															</div>
														}
														onClick={() => handleTemplateChange(template)}
													/>
												))}
											</div>
										</FormControl>
									</Grid>
									{/* <Grid item lg={8} md={8} sm={12}>
										<FormControl className={styles.formControl}>
											<Typography variant="body1" sx={{ marginBottom: 1 }}>
												{t("labels.customizations")}:
											</Typography>
											<Grid container spacing={1}>
												<Grid item lg={3}>
													<Select
														value={selectedCustomizationType}
														onChange={(e) =>
															setSelectedCustomizationType(e.target.value as string)
														}
														sx={{ width: "100%" }}
													>
														{Object.values(WidgetCustomizations).map((customization) => (
															<MenuItem key={customization} value={customization}>
																{t(`customizations.${customization}`)}
															</MenuItem>
														))}
													</Select>
												</Grid>
												<Grid item lg={8}>
													<TextField
														sx={{ width: "100%" }}
														value={customizationValue}
														onChange={(e) => setCustomizationValue(e.target.value)}
													/>
												</Grid>
												<Grid item lg={1} className={styles.customizationAddButton}>
													<Button
														type="button"
														onClick={handleCustomizationAdd}
														color="success"
														size="small"
													>
														{ct("common.add")}
													</Button>
												</Grid>
											</Grid>
											<div className={styles.customizationsContainer}>
												{widgetData?.customizations?.map((customization, index) => (
													<div className={styles.customizationItem} key={index}>
														<div className={styles.customizationInfo}>
															<label className={styles.customizationType}>
																{t(`customizations.${customization.type}`)}
															</label>
															{customization.value}
														</div>
														<Button
															type="button"
															color="error"
															size="small"
															onClick={() => handleDeleteCustomization(customization)}
														>
															<Close />
														</Button>
													</div>
												))}
											</div>
										</FormControl>
									</Grid> */}
									<Grid item lg={12}>
										<div className={styles.actionsContainer}>
											<Button variant="contained" color="primary" type="submit" className={styles.saveBtn}>
												{widgetData?.id ? ct("common.save") : ct("common.create")}
											</Button>
											<Button onClick={handleCancel} className={styles.cancelBtn}>{ct("common.cancel")}</Button>
										</div>
									</Grid>
								</Grid>
							</Grid>
							<Grid item lg={6} sm={12}>
								{widgetData.type === WidgetTypes.custom && (
									<Grid container spacing={3}>
										<Typography variant="body1" className={styles.videosSectionTitle}>
											{t("labels.videos")}:
										</Typography>
										<div className={styles.videosList}>
											<Loader loading={loading}>
												<Autocomplete
													options={[...videosList, { id: "loader" }]}
													getOptionLabel={(option) =>
														`${option?.description || ""} - ${option?.name || ""} - ${option?.id || ""}`
													}
													renderInput={(params) => (
														<TextField
															{...params}
															label="Select Video"
															variant="outlined"
														/>
													)}
													isOptionEqualToValue={(option, value) => option.id === value.id}
													renderOption={(props, option) => (
														<>
															{option?.id === "loader" ? (
																<>
																	{hasMore && (
																		<li key={option?.id} ref={sentryRef}>
																			<Loader loading={true}>
																				<></>
																			</Loader>
																		</li>
																	)}
																</>
															) : (
																<li {...props}>
																	<img
																		src={option.thumbnail_url}
																		alt={option.description}
																		className={styles.thumbnailStyle}
																		onClick={() => handleOpenVideoPopup(option)}
																	/>
																	<div
																		onClick={() =>
																			handleSelectVideo(undefined, option)
																		}
																	>
																		{option.description}
																	</div>
																</li>
															)}
														</>
													)}
													onInputChange={debouncedHandleSearchVideos}
												/>
											</Loader>
										</div>
										<div className={styles.selectedVideosList}>
											{widgetData?.videos?.map((video, index) => (
												<div className={styles.videoItem} key={video.id || index}>
													<div className={styles.videoContainer}>
														<video src={video.url} controls={true} />
														<Button
															type="button"
															onClick={() => handleVideoDeselect(video.id)}
															className={styles.deleteButton}
														>
															<DeleteOutline />
														</Button>
													</div>
												</div>
											))}
										</div>
									</Grid>
								)}
							</Grid>
						</Grid>
					</form>
				</div>
				{openVideoModal && (
					<Modal
						open={openVideoModal}
						onClose={closeVideoPopup}
						aria-labelledby="video-modal-title"
						aria-describedby="video-modal-description"
					>
						<Box sx={modalStyles.modalContainer}>
							{selectedVideo && (
								<VideoPlayer url={selectedVideo?.url} onClose={closeVideoPopup} autoplay={true} />
							)}
						</Box>
					</Modal>
				)}
			</Box>
		</PageLayout>
	);
};

export default observer(Widget);
