import ProductCard from "@components/common/ProductCard/ProductCard";
import { useAppStore } from "@hooks/useStores";
import { Video as VideoInterface } from "@models/videos";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { showSuccessToast } from "@utils/toast";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./VideoAiView.module.scss";

interface ProductsTabProps {
	products: any[];
	video?: VideoInterface;
}

const ProductsTab: React.FC<ProductsTabProps> = ({ products, video }) => {
	const { videosStore, productStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "videos.table.videoAiView" });
	const videoData: VideoInterface | undefined = video;
	const [selectedProducts, setSelectedProducts] = useState<any[]>(products);
	const [searchResults, setSearchResults] = useState<any[]>([]);
	const [searchQuery, setSearchQuery] = useState("");
	const [loadingProductsSearch, setLoadingProductsSearch] = useState(false);
	const [loadingProducts, setLoadingProducts] = useState(false);

	useEffect(() => {
		if (videoData && videoData.products && videoData.products.length > 0) {
			fetchProductsByIds((videoData.products ?? []).map((prod) => prod.external_id));
		}
	}, [video]);

	const handleFormSubmit = () => {
		const products: any[] = selectedProducts?.map((product: any) => ({
			external_id: product?.id,
		}));
		if (videoData?.id) {
			const dataToSend = {
				description: videoData.description,
				name: videoData.name,
				products: products,
			};

			videosStore
				.updateVideo(videoData.id, dataToSend)
				.then(() => {
					setSearchQuery("");
					showSuccessToast(t("productsUpdated"));
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};

	const fetchProductsByIds = async (productIds: any) => {
		setLoadingProducts(true);
		productStore
			.fetchProductsByIds({ productIds })
			.then((res: any) => {
				setSelectedProducts(res);
			})
			.finally(() => {
				setLoadingProducts(false);
			});
	};

	const searchProducts = async (query: any) => {
		setLoadingProductsSearch(true);
		productStore
			.searchProducts({ query: query || "" })
			.then((response: any) => {
				const result = (response?.products || []).map((product: any) => ({
					label: product?.name?.default ?? product.title,
					id: product?.id,
				}));

				setSearchResults(result);
			})
			.finally(() => {
				setLoadingProductsSearch(false);
			});
	};

	const handleProductSelect = async (_event: any, value: any) => {
		if (value) {
			setSearchQuery("");
			const data: any = (selectedProducts || []).map((ele) => ele?.id);
			data?.push(value?.id);
			await fetchProductsByIds(data);
		}
	};

	const handleProductRemove = (productId: any) => {
		const updatedProducts = selectedProducts?.filter((p: any) => p.id !== productId);
		setSelectedProducts(updatedProducts);
	};

	return (
		<Box className={styles.videosList}>
			<Box className={styles.videoAutoComplete}>
				<Autocomplete
					options={searchResults}
					getOptionLabel={(option: any) => option.label}
					onChange={handleProductSelect}
					inputValue={searchQuery}
					onInputChange={(_event, newInputValue) => {
						setSearchQuery(newInputValue);
						searchProducts(newInputValue);
					}}
					loading={loadingProducts || loadingProductsSearch}
					renderInput={(params) => (
						<TextField {...params} label="Type to search and select products" variant="outlined" />
					)}
					className={styles.txtField}
				/>
				<Button color="primary" variant="contained" onClick={handleFormSubmit} disabled={!searchQuery}>
					{t("save")}
				</Button>
			</Box>
			{selectedProducts &&
				selectedProducts.map((product: any) => (
					<ProductCard
						id={product.id}
						key={product.id}
						thumbnail={product.image.src}
						description={product.title}
						onRemove={handleProductRemove}
					/>
				))}
		</Box>
	);
};

export default ProductsTab;
