import { format } from "date-fns";

export const splitArray = <T>(arr: T[]): [T[], T[]] => {
	const firstItem = arr.length > 0 ? [arr[0]] : [];
	const remainingItems = arr.slice(1);
	return [firstItem, remainingItems];
};

export const formatDate = (date: Date, type: "start" | "end"): string => {
	const adjustedDate = new Date(date);

	if (type === "start") {
		adjustedDate.setHours(0, 0, 0, 0);
	} else if (type === "end") {
		adjustedDate.setHours(23, 59, 59, 999);
	}

	return format(adjustedDate, "yyyy-MM-dd HH:mm:ss");
};

export const getLanguageName = (langCode: string, locale: string = "en") => {
	try {
		const displayNames = new Intl.DisplayNames([locale], { type: "language" });
		return displayNames.of(langCode);
	} catch (error) {
		console.error("Error fetching language name:", error);
		return langCode; // Fallback to the code itself
	}
};
