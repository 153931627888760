import CreatorInfo from "@components/common/CreatorInfo/CreatorInfo";
import Loader from "@components/common/Loader";
import VideoCard from "@components/common/VideoCard/VideoCard";
import { useAppStore } from "@hooks/useStores";
import PageLayout from "@layouts/PageLayout";
import environmentStore from "@mobx/EnvironmentStore.ts";
import { Creator as CreatorInterface, Video } from "@models/videos";
import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styles from "./Creator.module.scss";

export type EditCreatorState = {
	CreatorToEdit?: CreatorInterface;
};

const Creator = () => {
	const { t } = useTranslation("translation", { keyPrefix: "creators.table" });
	const location = useLocation();
	const [isLoading, setIsLoading] = useState(false);
	const [creatorData, setCreatorData] = useState<CreatorInterface>(location.state?.CreatorToEdit ?? "");
	const { creatorsStore, videosStore } = useAppStore();
	const [videos, setVideos] = useState<Video[]>();

	const getData = async () => {
		try {
			setIsLoading(true);
			creatorsStore.getCreator(creatorData.id).then((res: CreatorInterface) => {
				setCreatorData(res);
			});
		} catch (e) {
			console.error(e);
		} finally {
			setIsLoading(false);
		}
	};

	const getVideos = async () => {
		try {
			setIsLoading(true);
			if (creatorData?.username) {
				await videosStore.getVideos({ tiktokerName: creatorData?.username });
				setVideos(videosStore.videos);
			}
		} catch (e) {
			console.error(e);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getData();
	}, [environmentStore.environment]);

	useEffect(() => {
		getVideos();
	}, [creatorData]);

	return (
		<PageLayout title={(creatorData?.username && creatorData?.nickname + " @" + creatorData.username) ?? ""} showBackBtn>
			<Box className={styles.container}>
				<Loader loading={isLoading || !creatorData?.username}>
					{creatorData && <CreatorInfo creatorId={creatorData.id} viewProfile={false} />}
					<Box className={styles.videosListContainer}>
						<Typography className={styles.sectionTitle}>{t("videos")}:</Typography>
						<Box className={styles.videosList}>
							{videos &&
								videos?.length > 0 &&
								videos.map((video) => (
									<VideoCard
										thumbnail={video.thumbnail_url}
										description={video.description}
										id={video.id}
										video={video}
									/>
								))}
						</Box>
					</Box>
				</Loader>
			</Box>
		</PageLayout>
	);
};

export default observer(Creator);
