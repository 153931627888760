import AuthService from "@services/AuthService.ts";
import axios from "axios";
import { showErrorToast } from "../utils/toast.tsx";
import { ENV_VARS } from "./env";
import environmentStore  from "@mobx/EnvironmentStore";

export function Axios(customHeaders = {}) {
	const instance = axios.create({
		baseURL: ENV_VARS.VITE_SVA_API_URL,
		headers: {
			"Content-Type": "application/json",
			...customHeaders,
		},
	});
	instance.interceptors.request.use(
		(config) => {
			if (!config.url?.includes("auth")) {
				const token = localStorage.getItem("accessToken");
				const environment = environmentStore.environment;
				if (token) {
					config.headers.Authorization = `Bearer ${token}`;
					config.headers.environment = environment;
				}
			}
			return config;
		},
		(error) => Promise.reject(error),
	);

	instance.interceptors.response.use(
		(response) => response,
		async (error) => {
			const originalRequest = error.config;

			if (error.response?.status === 401 && !originalRequest._retry) {
				originalRequest._retry = true;

				try {
					const refreshToken = localStorage.getItem("refreshToken") ?? "";
					const newAccessToken = await new AuthService().refreshAccessToken(refreshToken);

					localStorage.setItem("accessToken", newAccessToken);

					// Retry the original request with the new token
					originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
					return axios(originalRequest);
				} catch (error) {
					localStorage.clear();
					window.location.href = "/login";
				}
			}

			return Promise.reject(error);
		},
	);

	instance.interceptors.response.use(null, (error) => {
		if (error.code !== "ERR_CANCELED") {
			// const errorMessage = error?.response?.data?.message ?? undefined;
			const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;

			if (!expectedError) {
				showErrorToast(undefined, "123");
			}
		}

		return Promise.reject(error);
	});
	return instance;
}
