import { CancelOutlined } from "@mui/icons-material";
import { Box, Chip, InputLabel, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ChipSelect.module.scss";

interface ChipSelectProps {
	type: string;
	value: string[];
	handleChangeValue: (type: string, value: string[]) => void;
}

const ChipSelect: React.FC<ChipSelectProps> = ({ type, value, handleChangeValue }) => {
	const { t } = useTranslation("translation", { keyPrefix: "settings" });
	const [newValue, setNewValue] = useState("");

	const handleAddNewValue = () => {
		if (newValue.trim() !== "" && !value.includes(newValue)) {
			setNewValue("");
			handleChangeValue(type, [...value, newValue]);
		}
	};

	const handleDeleteValue = (selectedItem: any) => {
		const newValue = value.filter((item) => item !== selectedItem);
		handleChangeValue(type, newValue);
	};

	return (
		<Box>
			<InputLabel>{t(type.toLowerCase())}</InputLabel>
			{value.length === 0 ? (
				<Typography variant="body2" color="textSecondary">
					No {type.toLowerCase()} selected
				</Typography>
			) : (
				<Stack gap={1} direction="row" flexWrap="wrap" my={1.5}>
					{value.map((value, index) => (
						<Chip
							key={index}
							label={value}
							onDelete={() => handleDeleteValue(value)}
							deleteIcon={<CancelOutlined onMouseDown={(event) => event.stopPropagation()} />}
						/>
					))}
				</Stack>
			)}
			<TextField
				label={`Add New ${t(type.toLowerCase())}`}
				value={newValue}
				size="small"
				onChange={(e) => setNewValue(e.target.value)}
				onKeyPress={(e) => {
					if (e.key === "Enter") handleAddNewValue();
				}}
				className={styles.inputField}
			/>
		</Box>
	);
};

export default ChipSelect;
