import { Widget } from "@models/widgets";

export const defaultWidget: Widget = {
	customizations: [],
	description: "",
	environment: "",
	name: "",
	template: "",
	type: "",
	videos: [],
};

export enum WidgetTemplates {
	slider = "SLIDER",
	stories = "STORIES",
}
export enum WidgetTypes {
	product = "PRODUCT",
	custom = "CUSTOM",
}

export enum WidgetCustomizations {
	CUSTOMIZATION_1 = "CUSTOMIZATION_1"
}
