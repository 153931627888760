import { Box, Typography } from "@mui/material";
import React from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import styles from "./StackedBarChart.module.scss";

interface CustomStackedBarChartProps {
	data: Record<string, any>[];
	xAxisKey: string;
	stackKeys: string[];
	title?: string;
}

const CustomizedLegend = (props: any) => {
	const { payload } = props;
	return (
		<Box className={styles.legendsList}>
			{payload.map((entry: any, index: number) => (
				<Box key={index} className={styles.legendsItem}>
					<Box>
						<Box sx={{ backgroundColor: entry.payload.fill }} className={styles.bullet} />
					</Box>
					<Box className={styles.legendLabel}>
						<Typography className={styles.legendLabelText}>{entry.value}</Typography>
						<Typography className={styles.legendLabelValue}>{entry.payload.value}</Typography>
					</Box>
				</Box>
			))}
		</Box>
	);
};

type TooltipPayload = {
	name: string;
	value: string;
	payload: { date: string };
};

type CustomTooltipProps = {
	payload?: TooltipPayload[];
};

const formatTooltipValue = (value: string, name: string): string => {
	return `${value.replace("_", " ")}: ${name}`;
};

const CustomTooltipContent: React.FC<CustomTooltipProps> = ({ payload }) => {
	if (!payload || !payload.length) return null;

	return (
		<Box className={styles.customRechartsTooltip}>
			<Typography className={styles.rechartsTooltipLabel}>{payload[0].payload?.date}</Typography>
			<ul className={styles.rechartsTooltipItemList}>
				{payload.map((payloadItem, index) => (
					<li key={index}>
						<Typography className={styles.rechartsTooltipItem}>
							{formatTooltipValue(payloadItem.name, payloadItem.value)}
						</Typography>
					</li>
				))}
			</ul>
		</Box>
	);
};

const CustomStackedBarChart: React.FC<CustomStackedBarChartProps> = ({ data, xAxisKey, stackKeys }) => {
	const getColor = (index: number) => `var(--color-${index % 10})`;

	return (
		<Box className={styles.stackedBarChartWrap}>
			<ResponsiveContainer width="100%" height={330}>
				<BarChart data={data} margin={{ top: 20, right: 20, left: 0, bottom: 20 }}>
					<CartesianGrid stroke="#f8f8f9" strokeDasharray="3 3" />
					<XAxis
						dataKey={xAxisKey}
						tickSize={0}
						axisLine={{ stroke: "#E0E0E0", strokeWidth: 1 }}
						padding={{ left: 20 }}
						tick={({ payload, x, y, dy }) => (
							<text
								x={x}
								y={y + 20}
								dy={dy}
								textAnchor="middle"
								fill="#7B91B0"
								fontSize={13}
								fontFamily="Lato, sans-sarif"
							>
								{payload.value}
							</text>
						)}
					/>
					<Tooltip content={<CustomTooltipContent />} cursor={false} />
					<Legend content={<CustomizedLegend />} />
					{stackKeys.map((key, index) => {
						const radius: [number, number, number, number] =
							index === 0 ? [0, 0, 4, 4] : index === stackKeys.length - 1 ? [4, 4, 0, 0] : [0, 0, 0, 0];
						return <Bar key={key} dataKey={key} stackId="a" fill={getColor(index)} radius={radius} />;
					})}
				</BarChart>
			</ResponsiveContainer>
		</Box>
	);
};

export default CustomStackedBarChart;
