import styles from "./CreatorInfo.module.scss";

import Loader from "@components/common/Loader";
import { useCreatorsLogic } from "@hooks/useCreatorsLogic";
import { useAppStore } from "@hooks/useStores";
import { Creator } from "@models/videos";
import {
	AlternateEmailRounded,
	Diversity1Rounded,
	FavoriteRounded,
	Groups2Rounded,
	PeopleAltRounded,
	SlowMotionVideoRounded,
} from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import clsx from "clsx";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AvatarPlaceholder from "@assets/images/avatar-placeholder.jpg";

interface ChartHeaderProps {
	creatorId: string;
	viewProfile?: boolean;
}

const CreatorInfo: React.FC<ChartHeaderProps> = ({ creatorId, viewProfile = true }) => {
	const { t } = useTranslation("translation", { keyPrefix: "videos.table.videoAiView" });
	const { creatorsStore } = useAppStore();
	const { handleModifyCreator, goToTiktokPage } = useCreatorsLogic();
	const [creatorData, setCreatorData] = useState<Creator>();
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		getCreatorData();
	}, [creatorId]);

	const getCreatorData = async () => {
		try {
			if (creatorId) {
				setLoading(true);
				const res = await creatorsStore.getCreator(creatorId);
				setCreatorData(res);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Box className={styles.creatorSection}>
			<Loader loading={loading && !creatorData}>
				<Box className={styles.tiktokerInformation}>
					<Box className={styles.creatorInfo}>
						<Box
							className={styles.creatorThumbnail}
							style={{
								backgroundImage: `url(${creatorData?.profileImage || AvatarPlaceholder})`,
							}}
						></Box>
						<Box>
							<Typography className={styles.creatorName}>{creatorData?.nickname}</Typography>
							<Typography
								className={styles.username}
								onClick={() => goToTiktokPage(creatorData?.username)}
							>
								<AlternateEmailRounded />
								{creatorData?.username}
							</Typography>
							{viewProfile && creatorData && (
								<Button
									onClick={() => handleModifyCreator(creatorData)}
									className={styles.viewProfileBtn}
								>
									{t("viewProfile")}
								</Button>
							)}
						</Box>
					</Box>

					<Box>
						<Box className={styles.tiktokerStats}>
							<Box className={clsx(styles.statsItem, styles.commentCard)}>
								<PeopleAltRounded />
								<Typography className={styles.statsLabel}>{t("following")}</Typography>
								<Typography className={styles.statsValue}>
									{numeral(creatorData?.followingCount).format("0a").toUpperCase()}
								</Typography>
							</Box>
							<Box className={clsx(styles.statsItem, styles.collectCard)}>
								<Groups2Rounded />
								<Typography className={styles.statsLabel}>{t("followers")}</Typography>
								<Typography className={styles.statsValue}>
									{numeral(creatorData?.followersCount).format("0a").toUpperCase()}
								</Typography>
							</Box>
							<Box className={clsx(styles.statsItem, styles.favCard)}>
								<Diversity1Rounded />
								<Typography className={styles.statsLabel}>{t("hearts")}</Typography>
								<Typography className={styles.statsValue}>
									{numeral(creatorData?.heartCount).format("0a").toUpperCase()}
								</Typography>
							</Box>
							<Box className={clsx(styles.statsItem, styles.playCard)}>
								<SlowMotionVideoRounded />
								<Typography className={styles.statsLabel}>{t("videos")}</Typography>
								<Typography className={styles.statsValue}>
									{numeral(creatorData?.videoCount).format("0a").toUpperCase()}
								</Typography>
							</Box>
							<Box className={clsx(styles.statsItem, styles.shareCard)}>
								<FavoriteRounded />
								<Typography className={styles.statsLabel}>{t("likes")}</Typography>
								<Typography className={styles.statsValue}>
									{numeral(creatorData?.likesCount).format("0a").toUpperCase()}
								</Typography>
							</Box>
						</Box>
					</Box>
				</Box>
			</Loader>
		</Box>
	);
};

export default CreatorInfo;
