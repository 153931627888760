import CustomAreaChart from "@components/common/AreaChart/AreaChart";
import ChartHeader from "@components/common/ChartHeader/ChartHeader";
import { useDateRange } from "@hooks/useDateRange";
import { Box, Typography } from "@mui/material";
import AnalyticsService from "@services/AnalyticsService";
import React, { useCallback, useEffect, useState } from "react";
import styles from "./ProductPerformanceChart.module.scss";

interface ProductPerformanceChartData {
	date: string;
	value: number;
}

interface ProductPerformanceChartProps {
	previewOnly?: boolean;
	productId: number;
}

const ProductPerformanceChart: React.FC<ProductPerformanceChartProps> = ({ previewOnly, productId }) => {
	const analyticsService = new AnalyticsService();
	const { dateRange, handleDateChange } = useDateRange();
	const [engagements, setEngagements] = useState<ProductPerformanceChartData[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		loadProductPerformanceData(dateRange[0], dateRange[1]);
	}, [dateRange, productId]);

	const loadProductPerformanceData =useCallback(async (startDate: Date, endDate: Date): Promise<void>=>{
		setLoading(true);
		try {
			const response = await analyticsService.loadProductPerformanceChart(startDate, endDate, productId);
			if (response) {
				setEngagements(response);
			}
		} catch (error) {
			console.error("Error fetching engagements data:", error);
		} finally {
			setLoading(false);
		}
	},[productId]);

	return (
		<Box>
			<ChartHeader
				title="Product Performance"
				onDateChange={handleDateChange}
				value={dateRange}
				loading={loading}
				previewOnly={previewOnly}
			/>
			<Box className={styles.blockContentWrap}>
				{loading ? (
					<Typography>Loading...</Typography>
				) : engagements.length > 0 ? (
					<CustomAreaChart data={engagements} />
				) : (
					<Typography>No data available for the selected range.</Typography>
				)}
			</Box>
		</Box>
	);
};

export default ProductPerformanceChart;
