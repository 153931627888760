import { Environment } from "@constants/Environments";
import { makeAutoObservable } from "mobx";

class EnvironmentStore {
	environment: Environment = Environment.staging;

	constructor() {
		makeAutoObservable(this);
		const storedEnvironment = localStorage.getItem("environment");
		if (storedEnvironment) {
			this.environment = storedEnvironment as Environment;
		}
	}

	setEnvironment(environment: Environment) {
		this.environment = environment;
		localStorage.setItem("environment", environment);
	}
}

const environmentStore = new EnvironmentStore();

export default environmentStore;
