import { AlternateEmailRounded, FavoriteRounded } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { convertTimeToSocialFormat } from "@utils/dateFormate";
import React from "react";
import styles from "./VideoAiView.module.scss";

interface CommentsTabProps {
	comments: any[];
}

const CommentsTab: React.FC<CommentsTabProps> = ({ comments }) => {
	return (
		<Box className={styles.commentsWrapper}>
			{comments && comments.length > 0 && (
				<Box className={styles.commentsContainer}>
					{comments.map((comment, index) => (
						<Box key={index} className={styles.commentContainer}>
							<Box className={styles.comment}>
								<Typography className={styles.commentAuthor}>
									<AlternateEmailRounded /> {comment.author?.unique_id}
									<span className={styles.commentTime}>
										{convertTimeToSocialFormat(comment.create_time)}
									</span>
								</Typography>
								<Typography className={styles.commentText}>{comment.text}</Typography>
							</Box>
							<Typography className={styles.likesContainer}>
								<FavoriteRounded /> {comment.likesCount}
							</Typography>
						</Box>
					))}
				</Box>
			)}
		</Box>
	);
};

export default CommentsTab;
