import { useDateRange } from "@hooks/useDateRange";
import { Chat, Share, ThumbUp, Videocam } from "@mui/icons-material";
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AnalyticsService from "@services/AnalyticsService";
import clsx from "clsx";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import styles from "./ResentInsights.module.scss";

interface CardItemProps {
	icon: React.ReactNode; // Accept a React Node for the icon (Material-UI icons)
	value: string | number;
	text: string;
	subText: string;
	className: string;
}

const CardItem: React.FC<CardItemProps> = ({ icon, value, text, subText, className }) => {
	return (
		<Card className={`${styles.cardItem} ${className}`}>
			<CardContent>
				<div className={styles.cardIcon}>{icon}</div>
				<Typography variant="h6" className={styles.cardValue}>
					{value}
				</Typography>
				<Typography variant="body2" className={styles.cardText}>
					{text}
				</Typography>
				<Typography variant="caption" className={styles.cardSubText}>
					{subText}
				</Typography>
			</CardContent>
		</Card>
	);
};

const ResentInsights: React.FC = () => {
	const analyticsService = new AnalyticsService();

	const { dateRange } = useDateRange(1); // Fetch 2 days range
	const { dateRange: prevDateRange } = useDateRange(2); // Fetch 3 days range
	const [resentInsights, setResentInsights] = useState<any[]>([]);
	const [prevInsights, setPrevInsights] = useState<any[]>([]);
	const [videosCount, setVideosCount] = useState<number>(0);
	const [prevVideosCount, setPrevVideosCount] = useState<number>(0);

	useEffect(() => {
		loadResentInsights(dateRange[0], dateRange[1]);
		loadPrevInsights(prevDateRange[0], prevDateRange[1]);
		loadRecentVideosCount(dateRange[0], dateRange[1]);
		loadPrevVideosCount(prevDateRange[0], prevDateRange[1]);
	}, [dateRange, prevDateRange]);

	const loadResentInsights = async (startDate: Date, endDate: Date): Promise<void> => {
		try {
			const response = await analyticsService.loadEngagementChart(startDate, endDate);
			if (response) {
				setResentInsights(response);
			}
		} catch (error) {
			console.error("Error fetching engagements data:", error);
		}
	};

	const loadPrevInsights = async (startDate: Date, endDate: Date): Promise<void> => {
		try {
			const response = await analyticsService.loadEngagementChart(startDate, endDate);
			if (response) {
				setPrevInsights(response);
			}
		} catch (error) {
			console.error("Error fetching previous engagements data:", error);
		}
	};

	const loadRecentVideosCount = async (startDate: Date, endDate: Date): Promise<void> => {
		try {
			const response = await analyticsService.loadVideosCount(startDate, endDate);
			if (response) {
				setVideosCount(response?.[0]?.count);
			}
		} catch (error) {
			console.error("Error fetching video count:", error);
		}
	};

	const loadPrevVideosCount = async (startDate: Date, endDate: Date): Promise<void> => {
		try {
			const response = await analyticsService.loadVideosCount(startDate, endDate);
			if (response) {
				setPrevVideosCount(response?.[0]?.count);
			}
		} catch (error) {
			console.error("Error fetching previous video count:", error);
		}
	};

	const calculatePercentageChange = (current: number, previous: number): any => {
		if (previous === 0) return "+100%";
		const change = ((current - previous) / previous) * 100;
		const sign = change > 0 ? "+" : "";
		return (
			<Box
				className={clsx(styles.percentageChange, {
					[styles.negative]: sign === "" && change < 0,
				})}
			>{`${sign}${change.toFixed(1)}% form previous day`}</Box>
		);
	};

	return (
		<div className={styles.salesBlockWrap}>
			<div className={styles.blockHead}>
				<div className={styles.blockHeadLeft}>
					<Typography variant="h6" className={styles.blockTitle}>
						Recent Insights
					</Typography>
				</div>
			</div>
			{resentInsights.length > 0 && prevInsights.length > 0 && (
				<Grid container spacing={2} className={styles.cards}>
					<Grid item xs={12} sm={6} md={3}>
						<CardItem
							icon={<ThumbUp fontSize="large" style={{ color: "#f06292" }} />}
							value={numeral(resentInsights[0]?.Likes).format("0a").toUpperCase()}
							text="Total Likes"
							subText={calculatePercentageChange(resentInsights[0]?.Likes, prevInsights[0]?.Likes)}
							className={styles.cardMistyRose}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={3}>
						<CardItem
							icon={<Chat fontSize="large" style={{ color: "#ffd54f" }} />}
							value={numeral(resentInsights[0]?.Comments).format("0a").toUpperCase()}
							text="Total Comments"
							subText={calculatePercentageChange(resentInsights[0]?.Comments, prevInsights[0]?.Comments)}
							className={styles.cardLatte}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={3}>
						<CardItem
							icon={<Share fontSize="large" style={{ color: "#4fc3f7" }} />}
							value={numeral(resentInsights[0]?.Shares).format("0a").toUpperCase()}
							text="Total Shares"
							subText={calculatePercentageChange(resentInsights[0]?.Shares, prevInsights[0]?.Shares)}
							className={styles.cardNyanza}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={3}>
						<CardItem
							icon={<Videocam fontSize="large" style={{ color: "#9575cd" }} />}
							value={numeral(videosCount).format("0a").toUpperCase()}
							text="Total Videos"
							subText={calculatePercentageChange(videosCount, prevVideosCount)}
							className={styles.cardPalePurple}
						/>
					</Grid>
				</Grid>
			)}
		</div>
	);
};

export default ResentInsights;
