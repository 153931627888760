import AuthService, { LoginResponse } from "@services/AuthService.ts";
import QueryService from "@utils/QueryService.ts";
import { makeAutoObservable, runInAction } from "mobx";
import { User } from "../../models/user.ts";

export interface IAuthStore {
	accessToken: string | null;
	refreshToken: string | null;
	user: User | null;

	setAccessToken(token: string): void;

	setRefreshToken(token: string): void;

	authenticate(email: string, password: string): Promise<LoginResponse>;

	logout(): void;

	fetchUserInfo(): void;
}

export default class AuthStore implements IAuthStore {
	authService = new AuthService();
	userQueryService = new QueryService("/user");
	accessToken = localStorage.getItem("accessToken");
	refreshToken = localStorage.getItem("refreshToken");
	user = null;

	constructor() {
		makeAutoObservable(this);
	}

	async fetchUserInfo() {
		const response = await this.userQueryService.get("/");
		runInAction(() => {
			this.user = response;
		});
	}

	setAccessToken(token: string) {
		runInAction(() => {
			this.accessToken = token;
			localStorage.setItem("accessToken", token);
		});
	}

	setRefreshToken(token: string) {
		runInAction(() => {
			this.refreshToken = token;
			localStorage.setItem("refreshToken", token);
		});
	}

	async authenticate(email: string, password: string): Promise<LoginResponse> {
		const response = await this.authService.login(email, password);
		this.setAccessToken(response.access_token);
		this.setRefreshToken(response.refresh_token);
		return response;
	}

	logout() {
		runInAction(() => {
			this.accessToken = null;
			this.refreshToken = null;
			localStorage.clear();
		});
	}
}
