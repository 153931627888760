import { Widget } from "@models/widgets";
import QueryService from "@utils/QueryService.ts";
import { makeAutoObservable, runInAction } from "mobx";

export interface IWidgetsStore {
	widgets: any[];
	isError: boolean;
	isLoading: boolean;
    getWidgets(): Promise<void>;
	getWidget(widgetId: string): Promise<Widget>;
    createWidget(widgetData: any): Promise<void>;
    updateWidget(widgetId: string, widgetData: any): Promise<void>;
    deleteWidget(widgetId: string): Promise<void>;
}

export default class WidgetsStore implements IWidgetsStore {
	queryService = new QueryService("/widget");
	widgets: any[] = [];
	isError: boolean = false;
	isLoading: boolean = false;

	constructor() {
		makeAutoObservable(this);
	}

	getWidgets = async (): Promise<void> => {
		try {
			this.isError = false;
			this.isLoading = true;
			const widgets = await this.queryService.get("/");

			runInAction(() => {
				this.widgets = widgets;
				this.isError = false;
				this.isLoading = false;
			});
		} catch (error) {
			console.error("Error fetching widgets:", error);
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
		}
	};

	getWidget = async (widgetId: string): Promise<Widget> => {
        try {
            this.isError = false;
            this.isLoading = true;
            const widget = await this.queryService.get(`/${widgetId}`);
            return widget;
        } catch (error) {
            console.error("Error fetching widget:", error);
            runInAction(() => {
                this.isLoading = false;
                this.isError = true;
            });
            throw error;
        }
    };

    createWidget = async (widgetData: any): Promise<void> => {
        try {
            this.isError = false;
            this.isLoading = true;
            await this.queryService.post("/", widgetData);
            runInAction(() => {
                this.isLoading = false;
            });
        } catch (error) {
            console.error("Error creating widget:", error);
            runInAction(() => {
                this.isLoading = false;
                this.isError = true;
            });
            throw error;
        }
    };

    updateWidget = async (widgetId: string, widgetData: any): Promise<void> => {
        try {
            this.isError = false;
            this.isLoading = true;
            await this.queryService.put(`/${widgetId}`, widgetData);
            runInAction(() => {
                this.isLoading = false;
            });
        } catch (error) {
            console.error("Error updating widget:", error);
            runInAction(() => {
                this.isLoading = false;
                this.isError = true;
            });
            throw error;
        }
    };

    deleteWidget = async (widgetId: string): Promise<void> => {
        try {
            this.isError = false;
            this.isLoading = true;
            await this.queryService.delete(`/${widgetId}`);
            runInAction(() => {
                this.isLoading = false;
            });
        } catch (error) {
            console.error("Error deleting widget:", error);
            runInAction(() => {
                this.isLoading = false;
                this.isError = true;
            });
            throw error;
        }
    };
}
