import QueryService from "@utils/QueryService.ts";
import { makeAutoObservable } from "mobx";

export default class SettingsStore {
	queryService = new QueryService("/settings");

	constructor() {
		makeAutoObservable(this);
	}

	async updateSettings(settings: any) {
		try {
			await this.queryService.post("/", settings);
		} catch (error) {
			console.error("Error updating settings:", error);
		}
	}

	async getSettings() {
		try {
			return await this.queryService.get("/");
		} catch (error) {
			console.error("Error fetching settings:", error);
			return null;
		}
	}
}
