import { ButtonIcons } from "@layouts/TableLayout/TableLayout";
import { EmailOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
	Box,
	Pagination,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	styled,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Loader from "../Loader";
import TableButton from "../TableButton/TableButton";
import styles from "./CustomTable.module.scss";

export interface FilterBySearch {
	text: string;
	fields: string[];
}

export interface FilterByOptions {
	search?: FilterBySearch;
}

export type PaginationOptions = {
	rowsPerPage: number;
	totalRows: number;
	page?: number;
	loadNextPage: (pageNumber: number, filterBy?: FilterByOptions) => Promise<void>;
};

interface IProps {
	headerArray: any;
	rows: any;
	filterBy?: FilterByOptions;
	paginationOptions?: PaginationOptions;
	loading?: boolean;
	loadingRow?: { data: any; column: string };
	disableBottomButton?: boolean;
	hideBottomButton?: boolean;
	bottomButtonIconType?: ButtonIcons;
	bottomButtonText?: string;
	bottomButtonClick?: () => void;
	className?: string;
	sortable?: boolean;
}

const CustomTable = ({
	headerArray,
	rows,
	filterBy,
	paginationOptions,
	loading = false,
	loadingRow,
	hideBottomButton,
	disableBottomButton,
	bottomButtonIconType,
	bottomButtonText,
	bottomButtonClick,
	className,
	sortable = false,
}: IProps) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const currentPage = Number(searchParams.get("page")) || 1;

	const pagesCount = paginationOptions ? Math.ceil(paginationOptions.totalRows / paginationOptions.rowsPerPage!) : 1;

	const handleChangePage = async (_event: ChangeEvent<unknown> | null, page: number) => {
		setSearchParams({ page: page.toString() });
		await paginationOptions?.loadNextPage(page, filterBy);
	};

	useEffect(() => {
		if (paginationOptions?.page !== currentPage) {
			paginationOptions?.loadNextPage(currentPage, filterBy);
		}
	}, [currentPage]);

	// State for sorting
	const [order, setOrder] = useState<"asc" | "desc">("asc");
	const [orderBy, setOrderBy] = useState<string | null>(null);

	// Handle sorting
	const handleSort = (key: string) => {
		const isAsc = orderBy === key && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(key);
	};

	// Sorted rows
	const sortedRows = useMemo(() => {
		if (!orderBy) return rows;

		return [...rows].sort((a, b) => {
			const aValue = a.data[orderBy];
			const bValue = b.data[orderBy];

			if (aValue < bValue) return order === "asc" ? -1 : 1;
			if (aValue > bValue) return order === "asc" ? 1 : -1;
			return 0;
		});
	}, [rows, order, orderBy]);

	return (
		<Box className={styles.container}>
			<TableContainer
				sx={{ height: paginationOptions ? `${paginationOptions?.rowsPerPage * 63.38}px` : "auto" }}
				className={styles.tableContainer}
				component={Paper}
			>
				<Loader className={styles.loadingPlaceholder} loading={loading}>
					<Table aria-label="simple table" className={className} stickyHeader>
						<TableHead>
							<TableRow>
								{headerArray.map((el: any, index: number) => {
									return (
										<TableCell key={index}>
											{sortable ? (
												<TableSortLabel
													active={orderBy === el}
													direction={orderBy === el ? order : "asc"}
													onClick={() => handleSort(el)}
												>
													{el}
												</TableSortLabel>
											) : (
												el
											)}
										</TableCell>
									);
								})}
							</TableRow>
						</TableHead>
						<TableBody>
							{sortedRows.map((row: any, index: number) => (
								<TableRow
									key={index}
									sx={{
										backgroundColor: row["rowStyle"]?.backgroundColor,
										"&:last-child td, &:last-child th": { border: 0 },
									}}
								>
									{Object.entries(row.data).map(([column, v]: any, index: any) => {
										return (
											<TableCell key={index} align="left">
												<Loader
													className={styles.loadingCell}
													loading={
														!!(
															row.key &&
															column === loadingRow?.column &&
															row.key === loadingRow?.data.id
														)
													}
												>
													{v}
												</Loader>
											</TableCell>
										);
									})}
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Loader>
			</TableContainer>
			{bottomButtonText && !hideBottomButton && (
				<TableButton
					onClick={bottomButtonClick}
					buttonType="textSmall"
					disabled={disableBottomButton}
					className={styles.bottomBtn}
				>
					{bottomButtonIconType === "email" ? (
						<EmailOutlined className={styles.emailButtonIcon} />
					) : bottomButtonIconType === "plus" ? (
						<AddIcon />
					) : (
						<></>
					)}
					{bottomButtonText}
				</TableButton>
			)}
			{paginationOptions && pagesCount > 1 && (
				<Box
					marginTop={bottomButtonText ? "0" : "4vh"}
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
				>
					<TablePagination onChange={handleChangePage} count={pagesCount} page={paginationOptions.page} />
				</Box>
			)}
		</Box>
	);
};

export default observer(CustomTable);

const TablePagination = styled(Pagination)(() => ({
	ul: {
		"li:not(:last-child,:first-of-type)": {
			button: {
				fontWeight: 600,
				fontFamily: "Lato, sans-serif",
				fontSize: "12px",
				width: 26,
				minWidth: "unset",
				height: 26,
				borderRadius: "5px",
				backgroundColor: "#E7E7E7",
				"&.Mui-selected": {
					color: "white",
					backgroundColor: "var(--primary)",
					cursor: "pointer",
				},
			},
		},
	},
}));
