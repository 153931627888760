import ConfirmationPopup from "@components/common/ConfirmationPopup";
import { useAppStore } from "@hooks/useStores";
import { useVideosLogic } from "@hooks/useVideosLogic";
import PageLayout from "@layouts/PageLayout";
import environmentStore from "@mobx/EnvironmentStore.ts";
import { Video as VideoInterface } from "@models/videos";
import { Delete, Download } from "@mui/icons-material";
import { Box } from "@mui/material";
import VideoAiView from "@pages/Videos/VideoAiView/VideoAiView";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { IconButton } from "rsuite";
import styles from "./Video.module.scss";

export type EditVideoState = {
	VideoToEdit?: VideoInterface;
};

const Video = () => {
	const { t: ct } = useTranslation("translation");
	const { t } = useTranslation("translation", { keyPrefix: "videos.table" });
	const [isLoading, setIsLoading] = useState(false);
	const { closeDeletePopup, openDeletePopup, deleteVideo, handleDownloadVideo, handleOpenDeletePopup } =
		useVideosLogic();

	const location = useLocation();
	const videoId = location.state?.videoId ?? location.pathname.split("/")[2];
	const [videoData, setVideoData] = useState<VideoInterface>();
	const { videosStore, productStore } = useAppStore();
	const [selectedProducts, setSelectedProducts] = useState<any[]>([]);

	const getVideoData = async () => {
		try {
			setIsLoading(true);
			videosStore.getVideo(videoId).then((resVideoData: VideoInterface) => {
				setVideoData(resVideoData);
				if (resVideoData?.products && resVideoData.products.length > 0) {
					fetchProductsByIds((resVideoData.products ?? []).map((prod) => prod.external_id));
				}
			});
		} catch (e) {
			console.error(e);
		} finally {
			setIsLoading(false);
		}
	};
	useEffect(() => {
		getVideoData();
	}, [environmentStore.environment, videoId]);

	const fetchProductsByIds = async (productIds: any) => {
		productStore.fetchProductsByIds({ productIds }).then((res: any) => {
			setSelectedProducts(res);
		});
	};

	return (
		<PageLayout
			title={(videoData?.id && videoData?.name) ?? ""}
			isTitleEditable={true}
			onSaveTitle={(newTitle: string) => {
				if (videoData) {
					const products: any[] = selectedProducts?.map((product: any) => ({
						external_id: product?.id,
					}));
					const dataToSend = {
						description: videoData.description,
						name: newTitle,
						products: products,
					};

					videosStore.updateVideo(videoData.id, dataToSend).then(() => {
						setVideoData({ ...videoData, name: newTitle });
					});
				}
			}}
			actions={
				videoData && (
					<Box className={styles.actionsHeader}>
						<IconButton
							variant="contained"
							onClick={() => handleOpenDeletePopup(videoData)}
							className={styles.actionBtn}
						>
							<Delete />
						</IconButton>
						<IconButton
							variant="contained"
							onClick={() => handleDownloadVideo(videoData)}
							className={styles.actionBtn}
						>
							<Download />
						</IconButton>
					</Box>
				)
			}
			showBackBtn
		>
			<Box className={styles.container}>
				<VideoAiView video={videoData} isLoading={isLoading} products={selectedProducts} />
			</Box>
			<ConfirmationPopup
				onClose={closeDeletePopup}
				title={t("deletePopup.title")}
				description={t("deletePopup.description")}
				confirmButtonText={ct("delete")}
				open={openDeletePopup}
				onClick={deleteVideo}
			/>
		</PageLayout>
	);
};

export default observer(Video);
