import Table, { FilterByOptions, PaginationOptions } from "@components/common/CustomTable/CustomTable.tsx";
import DebouncedInput from "@components/common/DebouncedInput/DebouncedInput.tsx";
import TableButton from "@components/common/TableButton/TableButton.tsx";
import { EmailOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Key, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../../components/common/Loader/Loader.tsx";
import styles from "./TableLayout.module.scss";

export type ButtonIcons = "plus" | "email";

export interface TableRow<T> {
	key?: Key;
	data: T;
	rowStyle?: object;
}

interface IProps<T> {
	title?: string;
	description?: string;
	disablePrimaryButton?: boolean;
	primaryButtonIconType?: ButtonIcons;
	primaryButtonText?: string | ReactNode;
	primaryButtonClick?: () => void;
	hideSecondaryButton?: boolean;
	secondaryButtonIconType?: ButtonIcons;
	secondaryButtonText?: string | ReactNode;
	secondaryButtonClick?: () => void;
	disableBottomButton?: boolean;
	hideBottomButton?: boolean;
	bottomButtonIconType?: ButtonIcons;
	bottomButtonText?: string;
	bottomButtonClick?: () => void;
	headerArray: string[];
	tableData?: TableRow<T>[];
	emptyStateComponent?: ReactNode;
	enableSearch?: boolean;
	handleSearchChange?: (value: any) => void;
	searchBoxPlaceholder?: string;
	filterBy?: FilterByOptions;
	paginationOptions?: PaginationOptions;
	children?: ReactNode;
	loading?: boolean;
	loadingRow?: { data: any; column: string };
	className?: string;
	searchLoading?: boolean;
	customFilters?: React.ReactNode;
	sortable?: boolean;
}

const TableLayout = <T,>({
	title,
	description,
	disablePrimaryButton = false,
	primaryButtonClick,
	primaryButtonIconType,
	primaryButtonText,
	hideSecondaryButton = false,
	secondaryButtonText,
	secondaryButtonIconType,
	secondaryButtonClick = () => {
		window.open("LinkConstants.BRIA_API", "_blank");
	},
	disableBottomButton = false,
	hideBottomButton = false,
	bottomButtonText,
	bottomButtonIconType,
	bottomButtonClick,
	tableData = [],
	headerArray,
	emptyStateComponent,
	enableSearch,
	handleSearchChange,
	searchBoxPlaceholder = "Search",
	filterBy,
	paginationOptions,
	children,
	loading = false,
	loadingRow,
	className,
	searchLoading = false,
	customFilters,
	sortable = false,
}: IProps<T>) => {
	const { t } = useTranslation();
	const [searchValue, setSearchValue] = useState("");

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(event.target.value);
		handleSearchChange?.(event);
	};

	if (!secondaryButtonText) {
		secondaryButtonText = t("apiDocumentation");
	}

	return (
		<Box className={styles.container}>
			<Box className={styles.header}>
				{title && (
					<Box>
						<Typography className={styles.title}>{title}</Typography>
						<Typography className={styles.description}>{description}</Typography>
					</Box>
				)}
				{!hideSecondaryButton ||
					(primaryButtonText && (
						<>
							<Box className={styles.buttonsContainer}>
								{!hideSecondaryButton &&
									(typeof secondaryButtonText === "string" ? (
										<TableButton
											className={styles.secondaryBtn}
											onClick={secondaryButtonClick}
											buttonType="textMedium"
										>
											{secondaryButtonIconType === "email" ? (
												<EmailOutlined className={styles.emailButtonIcon} />
											) : secondaryButtonIconType === "plus" ? (
												<AddIcon />
											) : (
												<></>
											)}
											{secondaryButtonText}
										</TableButton>
									) : (
										secondaryButtonText
									))}
								{primaryButtonText &&
									(typeof primaryButtonText === "string" ? (
										<TableButton
											onClick={primaryButtonClick}
											buttonType="primaryMedium"
											disabled={disablePrimaryButton}
										>
											{primaryButtonIconType === "email" ? (
												<EmailOutlined className={styles.emailButtonIcon} />
											) : primaryButtonIconType === "plus" ? (
												<AddIcon />
											) : (
												<></>
											)}
											{primaryButtonText}
										</TableButton>
									) : (
										primaryButtonText
									))}
							</Box>
						</>
					))}
			</Box>
			{children}
			<Box className={styles.tableFilters}>
				{customFilters && customFilters}
				{(enableSearch || searchValue) && (
					<Box className={styles.searchContainer}>
						<DebouncedInput
							searchIcon
							onChange={handleSearch}
							classes={{
								root: styles.searchRootStyle,
							}}
							placeholder={searchBoxPlaceholder}
							height={"40px"}
							loading={searchLoading}
						/>
					</Box>
				)}
			</Box>
			<Box className={styles.tableWrapper}>
				{loading && <Loader className={styles.loading} loading={loading}/>}
				{tableData.length || !emptyStateComponent ? (
					<>
						<Box className={styles.innerTableContainer}>
							<Table
								{...{
									headerArray,
									filterBy,
									paginationOptions,
									loadingRow,
									hideBottomButton,
									disableBottomButton,
									bottomButtonIconType,
									bottomButtonText,
									bottomButtonClick,
									className,
									sortable
								}}
								rows={tableData}
								className={styles.table}
							
							/>
						</Box>

						{bottomButtonText && !hideBottomButton && (
							<TableButton
								onClick={bottomButtonClick}
								buttonType="textSmall"
								disabled={disableBottomButton}
								style={{ color: "#5300c9", alignSelf: "end" }}
							>
								{bottomButtonIconType === "email" ? (
									<EmailOutlined className={styles.emailButtonIcon} />
								) : bottomButtonIconType === "plus" ? (
									<AddIcon />
								) : (
									<></>
								)}
								{bottomButtonText}
							</TableButton>
						)}
					</>
				) : (
					<Box className={styles.emptyState} onClick={primaryButtonClick}>
						{emptyStateComponent}
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default observer(TableLayout);
