import { useState } from "react";

export const useDateRange = (daysAgo: number = 14) => {
	const today = new Date();
	const startDate = new Date(today);
	startDate.setDate(today.getDate() - daysAgo);

	const [dateRange, setDateRange] = useState<[Date, Date]>([startDate, today]);

	const handleDateChange = (newRange: [Date, Date]): void => {
		setDateRange(newRange);
	};

	return { dateRange, handleDateChange };
};
