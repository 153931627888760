import ChipSelect from "@components/common/ChipSelect";
import Loader from "@components/common/Loader";
import useErrorToast from "@hooks/useErrorToast";
import { useAppStore } from "@hooks/useStores";
import PageLayout from "@layouts/PageLayout";
import { Box, Button, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Settings.module.scss";

interface Setting {
	type: string;
	value: any;
}

const Settings = () => {
	const { t } = useTranslation("translation");
	const [settings, setSettings] = useState<Setting[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const { appStore } = useAppStore();
	const { showError } = useErrorToast();

	const getSettings = async () => {
		try {
			setLoading(true);
			const result = await appStore.settingsStore.getSettings();
			const parsedSettings: Setting[] = result.map((item: Setting) => {
				return {
					...item,
					value: item.value.split(",").map((value: string) => value.trim()),
				};
			});
			setSettings(parsedSettings);
		} catch (error) {
			showError("Failed to fetch settings. Please try again.");
		} finally {
			setLoading(false);
		}
	};

	const handleChangeValue = (type: string, value: string[]) => {
		setSettings((prevSettings) => {
			return prevSettings.map((item) => {
				if (item.type === type) {
					return {
						...item,
						value,
					};
				} else {
					return item;
				}
			});
		});
	};

	const handleSave = async () => {
		try {
			setLoading(true);
			const updatedSettings = settings.map((item) => {
				return {
					...item,
					value: item.value.join(","),
				};
			});
			await appStore.settingsStore.updateSettings(updatedSettings);
			await getSettings();
		} catch (error) {
			showError("Failed to update settings");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getSettings();
	}, []);

	return (
		<PageLayout title={t("sidebar.settings")}>
			<Box className={styles.container}>
				<Loader loading={loading} className={styles.loader}>
					<>
						<Grid container spacing={2} className={styles.gridContainer}>
							{settings?.map((item, index) => (
								<Grid item lg={4} md={6} sm={12} key={index}>
									<ChipSelect
										key={index}
										type={item.type}
										value={item.value}
										handleChangeValue={handleChangeValue}
									/>
								</Grid>
							))}
						</Grid>

						<Button variant="contained" color="primary" onClick={handleSave} className={styles.saveBtn}>
							Save
						</Button>
					</>
				</Loader>
			</Box>
		</PageLayout>
	);
};

export default observer(Settings);
