import { observer } from "mobx-react-lite";
import styles from "./VideoPlayer.module.scss";

interface IVideoPlayer {
	url?: string;
	onClose: () => void;
	autoplay?: boolean;
}

const VideoPlayer = ({ url, autoplay = false }: IVideoPlayer) => {
	return <video src={url} controls className={styles.videoPlayerStyle} autoPlay={autoplay}></video>;
};

export default observer(VideoPlayer);
