import RouterConstants from "@constants/RouterConstants";
import useErrorToast from "@hooks/useErrorToast";
import { useAppStore } from "@hooks/useStores";
import { TableRow } from "@layouts/TableLayout/TableLayout";
import environmentStore from "@mobx/EnvironmentStore";
import { Creator, CreatorTableRow } from "@models/videos";
import { AlternateEmailRounded, Visibility } from "@mui/icons-material";
import { Box } from "@mui/material";
import styles from "@pages/Creators/Creators.module.scss";
import { toJS } from "mobx";
import numeral from "numeral";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const useCreatorsLogic = () => {
	const { t } = useTranslation("translation", { keyPrefix: "creators.table" });
	const { creatorsStore } = useAppStore();
	const [tableData, setTableData] = useState<TableRow<CreatorTableRow>[]>([]);
	const [openDeletePopup, setOpenDeletePopup] = useState(false);
	const [openAiAnalysisView, setOpenAiAnalysisView] = useState(false);
	const [_videoToDelete, setVideoToDelete] = useState<Creator>();
	const [videoToViewAiAnalysis, setVideoToViewAiAnalysis] = useState<Creator>();
	const [openVideoModal, setOpenVideoModal] = useState(false);
	const [page, setPage] = useState(1);
	const [selectedVideo, setSelectedVideo] = useState<Creator | null>(null);
	const [searchLoading, setSearchLoading] = useState(false);
	const { showError } = useErrorToast();
	const navigate = useNavigate();
	const headerArray = [
		t("headers.name"),
		t("headers.username"),
		t("headers.followers"),
		t("headers.hearts"),
		t("headers.videos"),
		t("headers.actions"),
	];
	const rowsPerPage = 10;

	const loadNextPage = async (pageNumber: number) => {
		creatorsStore.getCreators({ page: pageNumber, perPage: rowsPerPage }).then(() => {
			setPage(pageNumber);
			const tableRows = creatorsStore.creators.map((creator, index) => createRow(creator, index));
			setTableData(tableRows);
		});
	};

	const createRow = (creator: any, index: number): TableRow<CreatorTableRow> => {
		return {
			rowStyle: {
				backgroundColor: index % 2 === 0 ? "#D9D9D91A" : "transparent",
			},
			key: creator.id,
			data: {
				name: (
					<Box className={styles.nameStyle}>
						<Box className={styles.profileImage} sx={{backgroundImage: `url(${creator.profileImage})`}}></Box>{creator.nickname}
					</Box>
				),
				username: (
					<Box className={styles.usernameStyle} onClick={()=> goToTiktokPage(creator.username)}>
						<AlternateEmailRounded/>{creator.username}
					</Box>
				),
				followers: (
					<Box className={styles.descriptionStyle}>
						{numeral(creator.followersCount).format("0a").toUpperCase()}
					</Box>
				),
				hearts: (
					<Box className={styles.descriptionStyle}>
						{numeral(creator.heartCount).format("0a").toUpperCase()}
					</Box>
				),
				videos: (
					<Box className={styles.descriptionStyle}>
						{numeral(creator.videoCount).format("0a").toUpperCase()}
					</Box>
				),
				actions: (
					<Box className={styles.actionsContainer}>
						{[
							// {
							// 	icon: <img src={aiIcon} className={styles.actionBtn} />,
							// 	onClick: () => handleViewAiAnalysis(video),
							// },
							{
								icon: <Visibility className={styles.actionBtn} />,
								onClick: () => handleModifyCreator(creator),
							},
							// {
							// 	icon: <Delete className={styles.actionBtn} />,
							// 	onClick: () => handleOpenDeletePopup(creator),
							// },
						].map((action, index) => (
							<Box key={index} onClick={action.onClick}>
								{action.icon}
							</Box>
						))}
					</Box>
				),
			},
		};
	};

	const deleteVideo = async () => {
		creatorsStore.deleteVideo(_videoToDelete!.id).then(() => {
			getCreators();
		});
		setOpenDeletePopup(false);
	};

	const handleOpenVideoPopup = (creator: Creator) => {
		setSelectedVideo(creator);
		setOpenVideoModal(true);
	};

	const closeVideoPopup = () => {
		setSelectedVideo(null);
		setOpenVideoModal(false);
	};

	const handleOpenDeletePopup = (creator: Creator) => {
		setOpenDeletePopup(true);
		setVideoToDelete(creator);
	};

	const closeDeletePopup = () => {
		setOpenDeletePopup(false);
		setVideoToDelete(undefined);
	};

	const closeAiAnalysisView = () => {
		setVideoToViewAiAnalysis(undefined);
		setOpenAiAnalysisView(false);
	};

	const handleModifyCreator = (creator: Creator) => {
		const editCreatorState: any = {
			CreatorToEdit: toJS(creator),
		};
		navigate(`${RouterConstants.CREATOR.path}/${creator.id}`, { state: editCreatorState });
	};

	const getCreators = async (searchQuery?: string) => {
		setSearchLoading(true);
		try {
			await creatorsStore.getCreators({ page: 1, perPage: rowsPerPage, username: searchQuery || undefined });
			const tableRows = creatorsStore.creators.map((video, index) => createRow(video, index));
			setTableData(tableRows);
		} catch (error) {
			showError(String(error));
		} finally {
			setSearchLoading(false);
		}
	};

	const searchCreators = (event: ChangeEvent<HTMLInputElement>) => {
		const searchQuery = event.target.value;
		getCreators(searchQuery);
	};

	const goToTiktokPage = (username?: string) => {
		if (username) {
			const url = `https://www.tiktok.com/@${username}`;
			window.open(url, "_blank");
		}
	};

	useEffect(() => {
		getCreators();
	}, [environmentStore.environment]);

	return {
		creatorsStore,
		headerArray,
		tableData,
		selectedVideo,
		openVideoModal,
		openDeletePopup,
		_videoToDelete,
		closeVideoPopup,
		closeDeletePopup,
		deleteVideo,
		handleOpenVideoPopup,
		handleOpenDeletePopup,
		handleModifyCreator,
		videoToViewAiAnalysis,
		openAiAnalysisView,
		closeAiAnalysisView,
		paginationOptions: {
			rowsPerPage,
			totalRows: creatorsStore.totalRows || 0,
			loadNextPage,
			page,
		},
		searchCreators,
		searchLoading,
		goToTiktokPage,
		getCreators
	};
};
