import {
	CardGiftcardRounded,
	CommentRounded,
	FavoriteRounded,
	PlayArrowRounded,
	ShareRounded,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import numeral from "numeral";
import React from "react";
import styles from "./VideoAiView.module.scss";

interface VideoStatsProps {
	stats: {
		diggCount: number;
		shareCount: number;
		commentCount: number;
		playCount: number;
		collectCount: number;
	};
	t: (key: string) => string;
}

const VideoStats: React.FC<VideoStatsProps> = ({ stats, t }) => {
	return (
		<Box className={styles.stats}>
			<Box className={clsx(styles.statsItem, styles.favCard)}>
				<FavoriteRounded />
				<Typography className={styles.statsLabel}>{t("likes")}</Typography>
				<Typography className={styles.statsValue}>
					{numeral(stats.diggCount).format("0a").toUpperCase()}
				</Typography>
			</Box>
			<Box className={clsx(styles.statsItem, styles.shareCard)}>
				<ShareRounded />
				<Typography className={styles.statsLabel}>{t("shares")}</Typography>
				<Typography className={styles.statsValue}>
					{numeral(stats.shareCount).format("0a").toUpperCase()}
				</Typography>
			</Box>
			<Box className={clsx(styles.statsItem, styles.commentCard)}>
				<CommentRounded />
				<Typography className={styles.statsLabel}>{t("comments")}</Typography>
				<Typography className={styles.statsValue}>
					{numeral(stats.commentCount).format("0a").toUpperCase()}
				</Typography>
			</Box>
			<Box className={clsx(styles.statsItem, styles.playCard)}>
				<PlayArrowRounded />
				<Typography className={styles.statsLabel}>{t("plays")}</Typography>
				<Typography className={styles.statsValue}>
					{numeral(stats.playCount).format("0a").toUpperCase()}
				</Typography>
			</Box>
			<Box className={clsx(styles.statsItem, styles.collectCard)}>
				<CardGiftcardRounded />
				<Typography className={styles.statsLabel}>{t("collect")}</Typography>
				<Typography className={styles.statsValue}>
					{numeral(stats.collectCount).format("0a").toUpperCase()}
				</Typography>
			</Box>
		</Box>
	);
};

export default VideoStats;
