import RouterConstants from "@constants/RouterConstants";
import useErrorToast from "@hooks/useErrorToast";
import { useAppStore } from "@hooks/useStores";
import { TableRow } from "@layouts/TableLayout/TableLayout";
import environmentStore from "@mobx/EnvironmentStore";
import { Video, VideoTableRow } from "@models/videos";
import { Delete, Download, Visibility } from "@mui/icons-material";
import { Box } from "@mui/material";
import styles from "@pages/Videos/Videos.module.scss";
import { getFormattedDate } from "@utils/dateFormate";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const useVideosLogic = () => {
	const { t } = useTranslation("translation", { keyPrefix: "videos.table" });
	const { videosStore } = useAppStore();
	const [tableData, setTableData] = useState<TableRow<VideoTableRow>[]>([]);
	const [openDeletePopup, setOpenDeletePopup] = useState(false);
	const [openAiAnalysisView, setOpenAiAnalysisView] = useState(false);
	const [_videoToDelete, setVideoToDelete] = useState<Video>();
	const [videoToViewAiAnalysis, setVideoToViewAiAnalysis] = useState<Video>();
	const [openVideoModal, setOpenVideoModal] = useState(false);
	const [page, setPage] = useState(1);
	const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);
	const [searchLoading, setSearchLoading] = useState(false);
	const { showError } = useErrorToast();
	const navigate = useNavigate();
	const headerArray = [
		t("headers.thumbnail_url"),
		t("headers.name"),
		t("headers.description"),
		t("headers.status"),
		t("headers.createdAt"),
		t("headers.actions"),
	];
	const rowsPerPage = 10;

	const loadNextPage = async (pageNumber: number) => {
		videosStore.getVideos({ page: pageNumber, perPage: rowsPerPage }).then(() => {
			setPage(pageNumber);
			const tableRows = videosStore.videos.map((video, index) => createRow(video, index));
			setTableData(tableRows);
		});
	};

	const createRow = (video: Video, index: number): TableRow<VideoTableRow> => {
		const formattedDate = getFormattedDate(video?.created_at);
		return {
			rowStyle: {
				backgroundColor: index % 2 === 0 ? "#D9D9D91A" : "transparent",
			},
			key: video.id,
			data: {
				thumbnail_url: (
					<Box className={styles.imageWrapperStyle}>
						<img
							className={styles.imageStyle}
							src={video.thumbnail_url}
							alt="Video Thumbnail"
							onClick={() => handleOpenVideoPopup(video)}
						/>
					</Box>
				),
				name: <Box className={styles.nameStyle}>{video.name}</Box>,
				description: <Box className={styles.descriptionStyle}>{video.description}</Box>,
				status: (
					<Box className={styles.statusStyle}>
						<span
							className={
								video.status === "PENDING"
									? styles.pendingStatus
									: video.status === "SKIPPED"
										? styles.skippedStatus
										: styles.readyStatus
							}
						>
							{video.status === "SKIPPED" ? t("skipped") : video.status}
						</span>
					</Box>
				),
				createdAt: <Box>{formattedDate}</Box>,
				actions: (
					<Box className={styles.actionsContainer}>
						{[
							// {
							// 	icon: <img src={aiIcon} className={styles.actionBtn} />,
							// 	onClick: () => handleViewAiAnalysis(video),
							// },
							{
								icon: <Visibility className={styles.actionBtn} />,
								onClick: () => handleModifyVideo(video),
							},
							{
								icon: <Delete className={styles.actionBtn} />,
								onClick: () => handleOpenDeletePopup(video),
							},
							{
								icon: <Download className={styles.actionBtn} />,
								onClick: () => handleDownloadVideo(video),
							},
						].map((action, index) => (
							<Box key={index} onClick={action.onClick}>
								{action.icon}
							</Box>
						))}
					</Box>
				),
			},
		};
	};

	const deleteVideo = async () => {
		videosStore.deleteVideo(_videoToDelete!.id).then(() => {
			getVideos();
		});
		setOpenDeletePopup(false);
	};

	const handleOpenVideoPopup = (video: Video) => {
		setSelectedVideo(video);
		setOpenVideoModal(true);
	};

	const closeVideoPopup = () => {
		setSelectedVideo(null);
		setOpenVideoModal(false);
	};

	const handleOpenDeletePopup = (video: Video) => {
		setOpenDeletePopup(true);
		setVideoToDelete(video);
	};

	const closeDeletePopup = () => {
		setOpenDeletePopup(false);
		setVideoToDelete(undefined);
	};

	const closeAiAnalysisView = () => {
		setVideoToViewAiAnalysis(undefined);
		setOpenAiAnalysisView(false);
	};

	const handleDownloadVideo = useCallback((video: Video) => {
		if (!video || !video.url) {
			console.error("Video URL is undefined or null");
			return;
		}
		const url = video.url;
		const name = url.split("/");
		const fileName = name[name.length - 1];
		fetch(url)
			.then((response) => response.blob())
			.then((blob) => {
				const blobUrl = URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = blobUrl;
				link.setAttribute("download", fileName);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
			.catch((error) => {
				console.error("Error downloading video:", error);
			});
	}, []);

	const handleModifyVideo = (video: Video) => {
		navigate(`${RouterConstants.VIDEO.path}/${video.id}`, { state: { videoId: video.id } });
	};

	const getVideos = async (searchQuery?: string, postedAt?: [Date, Date], updatedAt?: [Date, Date]) => {
		setSearchLoading(true);
		try {
			await videosStore.getVideos({ page: 1, perPage: rowsPerPage, query: searchQuery || undefined, postedAt: postedAt, updatedAt: updatedAt });
			const tableRows = videosStore.videos.map((video, index) => createRow(video, index));
			setTableData(tableRows);
		} catch (error) {
			showError(String(error));
		} finally {
			setSearchLoading(false);
		}
	};

	const searchVideos = (event: ChangeEvent<HTMLInputElement>) => {
		const searchQuery = event.target.value;
		getVideos(searchQuery);
	};

	useEffect(() => {
		getVideos();
	}, [environmentStore.environment]);

	return {
		videosStore,
		headerArray,
		tableData,
		selectedVideo,
		openVideoModal,
		openDeletePopup,
		_videoToDelete,
		closeVideoPopup,
		closeDeletePopup,
		deleteVideo,
		handleOpenVideoPopup,
		handleOpenDeletePopup,
		handleDownloadVideo,
		handleModifyVideo,
		videoToViewAiAnalysis,
		openAiAnalysisView,
		closeAiAnalysisView,
		paginationOptions: {
			rowsPerPage,
			totalRows: videosStore.totalRows || 0,
			loadNextPage,
			page,
		},
		searchVideos,
		searchLoading,
		getVideos
	};
};
