import { ArrowBackRounded, Edit, Save } from "@mui/icons-material";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./PageLayout.module.scss";

interface PageLayoutProps {
	title: string;
	isTitleEditable?: boolean;
	onSaveTitle?: (newTitle: string) => void;
	showBackBtn?: boolean;
	actions?: React.ReactNode;
	children: React.ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({
	title,
	isTitleEditable = false,
	onSaveTitle,
	showBackBtn = false,
	actions,
	children,
}) => {
	const [isEditing, setIsEditing] = useState(false);
	const [editableTitle, setEditableTitle] = useState(title);
	const navigate = useNavigate();

	useEffect(() => {
		setEditableTitle(title);
	}, [title]);

	const handleEditClick = () => {
		setIsEditing(true);
	};

	const handleSaveClick = () => {
		if (onSaveTitle) {
			onSaveTitle(editableTitle);
		}
		setIsEditing(false);
	};

	const goBack = () => {
		navigate(-1);
	};

	return (
		<Box className={styles.pageContainer}>
			<Box className={styles.header}>
				<Box className={styles.titleContainer}>
					{showBackBtn && (
						<IconButton className={styles.backBtn} onClick={goBack}>
							<ArrowBackRounded />
						</IconButton>
					)}
					{isEditing && title ? (
						<Box className={styles.editableTitle}>
							<TextField
								value={editableTitle}
								onChange={(e) => setEditableTitle(e.target.value)}
								variant="outlined"
								size="small"
								className={styles.titleInput}
							/>
							<IconButton onClick={handleSaveClick} className={styles.saveButton}>
								<Save />
							</IconButton>
						</Box>
					) : (
						<Typography variant="h6" className={styles.pageTitle}>
							{title}
						</Typography>
					)}
					{isTitleEditable && !isEditing && (
						<IconButton onClick={handleEditClick} className={styles.editButton}>
							<Edit />
						</IconButton>
					)}
				</Box>
				{actions && <Box className={styles.pageActions}>{actions}</Box>}
			</Box>
			<Box className={styles.pageWrapper}>{children}</Box>
		</Box>
	);
};

export default PageLayout;
