import InsightsSwitch from "@components/common/InsightsSwitch/InsightsSwitch";
import EmotionsChart from "@components/dashboard/EmotionsChart/EmotionsChart";
import EngagementChart from "@components/dashboard/EngagementChart/EngagementChart";
import SentimentDistributionChart from "@components/dashboard/SentimentDistributionChart/SentimentDistributionChart";
import TopProducts from "@components/dashboard/TopProducts/TopProducts";
import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import styles from "./Insights.module.scss";

const Insights = () => {
	return (
		<>
			<Box className={styles.pageHeader}>
				<Typography className={styles.pageTitle} variant="h5">
					Insights
				</Typography>
				<InsightsSwitch />
			</Box>
			<Box className={styles.chartsRow}>
				{/* Engagement Overview */}

				<Box className={styles.BoxContainer}>
					<EngagementChart />
				</Box>

				{/* Sentiment Distribution */}
				<Box className={styles.BoxContainer}>
					<SentimentDistributionChart />
				</Box>
			</Box>
			<Box className={styles.chartsRow}>
				{/* Emotion Breakdown */}
				<Box className={styles.BoxContainer}>
					<EmotionsChart />
				</Box>

				{/* Top Products */}
				<Box className={styles.BoxContainer}>
					<TopProducts />
				</Box>
			</Box>
		</>
	);
};

export default observer(Insights);
